<template>
  <a-modal
    v-model:visible="isShow"
    :footer="null"
    class="ucs-simple-modal"
    :bodyStyle="{ padding: 0 }"
    :closable="false"
    :keyboard="false"
    :maskClosable="false"
  >
    <div class="modal-dialog modal-md modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">유캔싸인 이용약관</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="onCancel"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <textarea class="terms-readonly" readonly="">
&lt; 제1장 총칙 &gt;

제1조 (목적)
본 약관은 (주)유디아이디(이하 회사라 함)가 제공하는 유캔싸인 서비스 이용과 관련하여, 회사와 회원과의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.

제2조 (정의)
본 약관에서 사용하는 용어의 정의는 다음과 같습니다.

1. ‘서비스’라 함은 클라우드 기반의 간편 전자계약 서비스 유캔싸인을 말합니다.
2. ‘회원’이라 함은 서비스에 접속하여 본 약관에 따라 회사와 이용계약을 체결하고 회사가 제공하는 서비스를 이용하는 자를 말합니다.
3. ‘개인정보’라 함은 서비스를 이용하기 위해 회원의 이름, 이메일 주소 등 회원이 서비스에 입력하는 개인정보를 말합니다.
4. ‘유료 서비스’라 함은 회사가 유료로 제공하는 각종 서비스를 말합니다.
5. ‘서명 요청자’라 함은 서명 참여자에게 문서에 대한 전자서명을 요청하는 회원을 말합니다.
6. ‘서명 참여자’라 함은 서명 요청자로부터 전자서명을 요청 받은 문서에 전자서명을 하는 이용자를 말합니다.

본 약관에서 사용하는 용어의 정의는 제1항에서 정하는 것을 제외하고는 관련 법령에서 정하는 바에 의하며, 관련 법령에서 정하지 않는 것은 일반적인 상관례에 의합니다.

제3조 (약관의 게시와 개정)
1. 회사는 본 약관의 내용과 상호, 대표자의 성명, 사업자등록번호 등을 이용자가 알 수 있도록 서비스 초기화면에 게시하거나 기타의 방법으로 회원에게 공지합니다.
2. 회사는 약관의 규제에 관한 법률, 전기통신기본법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관련 법령을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.
3. 회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 개정약관의 적용일자 7일전부터 적용일자 전일까지 공지합니다.
다만, 회원에게 불리한 약관의 개정의 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지하며, 일정기간 서비스 내 로그인 시 동의창 또는 전자적 수단을 통해 따로 명확히 통지하도록 합니다.
4. 회원은 변경된 약관에 대해 거부할 권리가 있습니다. 회사가 제3항의 공지 또는 통보를 하면서 개정 약관의 적용/시행일까지 회원이 거부 의사를 표시하지 아니할 경우 약관의 개정에 동의한 것으로 간주한다는 내용을 고지하였으나, 회원이 명시적으로 약관 변경에 대한 거부의사를 표시하지 아니하면, 회사는 회원이 적용/시행일자 부로 변경 약관에 동의한 것으로 간주합니다. 개정/변경 약관에 대하여 거부의사를 표시한 회원은 계약의 해지 또는 회원 탈퇴를 선택할 수 있습니다.

제4조 (약관의 해석)
본 약관에서 규정하지 않은 사항에 관해서는 약관의 규제에 관한 법률, 전기통신기본법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등의 관계법령에 따릅니다.

제5조 (이용계약의 성립)
1. 회사와 회원 사이의 서비스 이용계약(이하 이용계약이라 함)은 서비스를 이용하고자 하는 자(이하 가입신청자라 함)의 회원가입 신청(페이앱 회원가입) 및 본 약관과 개인정보 처리방침의 내용에 동의한 것에 대한 회사의 이용승낙으로 성립합니다.
2. 본 약관 및 개인정보처리방침에 대한 동의의 의사표시는 가입신청자가 유캔싸인에 처음 접속 시 본 약관 및 개인정보처리방침에 대한 동의 버튼을 누름으로써 성립합니다.
3. 회사는 가입신청자의 신청에 대하여 서비스 이용을 승낙함을 원칙으로 합니다. 다만, 회사는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다.
1) 가입신청자가 본 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우
2) 실명이 아니거나 타인의 명의를 이용하여 가입 신청한 경우
3) 허위의 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않은 경우
4) 만 14세 미만 아동이 정보통신망 이용촉진 및 정보보호 등에 관한 법률에서 정한 개인정보 입력 시 법정대리인의 동의를 얻지 아니한 경우
5) 가입신청자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우
4. 회사는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.
5. 회사가 제2항, 제3항 및 제4항에 따라 회원가입신청을 승낙하지 아니하거나 유보한 경우, 회사는 원칙적으로 그 사실을 가입신청자에게 알리도록 합니다.
6. 이용계약의 성립 시기는 회사가 가입완료를 신청절차 상에서 표시한 시점으로 합니다.
7. 회사는 회원에 대해 회사정책에 따라 등급별로 구분하여 이용시간, 이용횟수, 서비스 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.
8. 본 약관은 회원이 약관에 동의한 날로부터 회원 탈퇴 시까지 적용하는 것을 원칙으로 합니다.
다만, 본 약관의 일부 조항은 회원이 탈퇴 후에도 유효하게 적용될 수 있습니다.

제6조 (이용자 정보의 제공)
1. 서비스를 이용하고자 하는 이용자는 이름, 이메일 등 개인정보를 제공하고, 서비스에서 사용할 이메일은 최초 1회 인증해야 합니다.
2. 이용자가 제1항에서 정한 인증을 거치지 않은 경우 서비스 이용이 제한될 수 있으며, 실명으로 등록하지 않은 이용자 또는 회원은 일체의 권리를 주장할 수 없습니다.
3. 타인의 명의를 도용하여 이용신청을 한 회원의 모든 ID는 삭제되며, 관계법령에 따라 처벌을 받을 수 있습니다.

제7조 (개인 정보의 보호 및 관리)
1. 회사는 관련 법령이 정하는 바에 따라 계정정보를 포함한 회원의 개인정보를 보호하기 위하여 노력합니다. 회원의 개인정보 보호 및 사용에 대해서는 관련 법령 및 회사가 별도로 고지하는 개인정보처리방침에 따릅니다.
다만, 회사가 제공하는 공식 서비스 사이트 이외의 링크된 사이트에서는 회사의 개인정보처리방침이 적용되지 않습니다.
2. 회사는 회원의 귀책사유로 인하여 노출된 회원의 계정정보를 포함한 모든 정보에 대해서는 일체의 책임을 지지 않습니다.

제8조 (회원의 계정 및 비밀번호)
1. 회사는 회원이 원활하게 서비스를 이용할 수 있도록 회원 아이디와 비밀번호를 제공하고 있습니다. 다만, 회원의 계정이 반사회적이거나 미풍양속을 해치거나 또는 운영자로 오인할 우려가 있는 경우에는 해당 계정의 사용을 거부하거나 제한할 수 있습니다.
2. 회사는 계정정보를 통하여 당해 회원의 서비스 이용가능여부 등 제반 이용자 관리 업무를 수행합니다.
3. 회원은 자신의 계정정보에 대해 책임을 가지고 관리 하여야 합니다. 회원이 본인의 계정정보를 소홀히 관리하거나 제3자에게 이용을 승낙함으로써 발생하는 손해에 대하여는 회원에게 책임이 있습니다.
4. 회원은 회사가 정한 기준을 충족하는 범위 내에서 자유롭게 비밀번호를 정할 수 있으며, 정해진 비밀번호는 회원이 원하는 경우 언제든지 페이앱 서비스에서 변경이 가능합니다.
5. 회원은 서비스의 이용을 위하여 사용하는 비밀번호에 대한 보호 및 관리 책임을 부담합니다. 다만, 회사는 보안 등을 이유로 회원에게 정기적 또는 비정기적으로 비밀번호의 변경을 권고할 수 있습니다.

제9조 (회원에 대한 통지)
1. 회사가 회원에 대한 통지를 하는 경우 본 약관에 별도 규정이 없는 한 SMS(단문 메세지), 이메일 주소 등으로 할 수 있습니다.
2. 회사는 회원 전체에 대한 통지의 경우 7일 이상 회사의 홈페이지 또는 공지사항 게시판에 게시함으로써 제1항의 통지에 갈음할 수 있습니다.
다만, 회원의 권리, 의무에 중대한 영향을 주는 내용을 통지하는 경우에는 적용일자 30일 전부터 게시하도록 합니다.

제10조 (서비스의 이용시간 및 중지)
1. 회사는 회원의 회원가입을 승낙한 때부터 서비스를 개시합니다. 단, 회사의 필요에 따라 지정된 일자부터 서비스를 제공할 수 있습니다.
2. 제1항의 경우 회사는 그 내용 및 시간을 홈페이지에 공지합니다. 단, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
3. 회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검 시간은 서비스 제공화면에 공시한 바에 따릅니다.

제11조 (서비스의 내용 및 변경)
1. 회원은 회사가 제공하는 서비스를 본 약관, 운영정책 등 회사가 정한 규칙에 따라 이용할 수 있습니다.
2. 회사가 회원에게 제공하는 서비스에 대하여 회사는 제작, 변경, 유지, 보수에 관한 포괄적인 권한을 가집니다.
3. 회사는 새로운 서비스 내용, 각종 버그 패치 등 서비스의 운영상 또는 기술상의 상당한 이유가 있는 경우, 제공하고 있는 서비스의 전부 또는 일부를 상시적으로 수정, 추가, 폐지 등 변경할 수 있습니다.
4. 회사는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 기획이나 운영상 또는 회사의 긴박한 상황 등 필요에 의해 수정, 중단, 변경할 수 있으며 이에 대하여 관련 법령상 특별한 규정이 없는 한 회원에게 별도의 보상을 하지 않습니다.
5. 회사는 다음 각호에 해당하는 경우 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다.
1) 전시, 사변, 천재지변 또는 국가비상사태 등 불가항력적인 사유가 있는 경우
2) 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우
3) 서비스용 설비의 보수 등 공사로 인한 부득이한 경우
4) 기타 회사의 제반 사정으로 서비스를 할 수 없는 경우
6. 회사는 서비스가 변경되거나 중지된 원인이 회사의 고의 또는 중대한 과실로 인한 경우를 제외하고는 서비스의 변경 및 중지로 발생하는 문제에 대해서 책임을 부담하지 않습니다.

제12조 (정보의 제공 및 광고의 게재)
1. 회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보를 회사의 홈페이지에 게시하거나 이메일 등을 이용하여 회원에게 제공할 수 있습니다. 다만, 회원은 관련 법령에 따른 거래 관련 정보 등을 제외한 정보의 전송에 대하여 언제든지 이메일 등을 통하여 수신거절 의사표시를 할 수 있습니다.
2. 회사는 본 서비스 등을 유지하기 위하여 광고를 게재할 수 있으며, 회원은 서비스 이용 시 노출되는 광고게재에 대하여 동의합니다.
3. 회사가 제공하는 제3자가 주체인, 제2항의 광고에 회원이 참여하거나 교신 또는 거래를 함으로써 발생하는 손실과 손해에 대해서 회사는 어떠한 책임도 부담하지 않습니다.
4. 회사는 적법하게 수집한 회원의 개인정보를 활용하여 제2항의 광고 등을 메일주소 등을 활용하여 발송할 수 있으며, 이용자가 원하지 않는 경우에는 언제든지 수신 거부할 수 있습니다.

제13조 (권리의 귀속)
서비스 및 서비스 내 회사가 제작한 콘텐츠 등에 대한 저작권 및 지적재산권은 회사에 귀속됩니다.

제14조 (회원의 계약 해지)
1. 회원은 고객센터를 통해 이용계약 해지 신청을 할 수 있으며, 회사는 관련법령 등에서 정하는 바에 따라 이를 즉시 처리하여야 합니다.
2. 다음 각호에 해당하는 경우를 제외한 나머지 회원의 모든 정보는 탈퇴 시점 이후 바로 삭제되며 복구할 수 없습니다.
1) 서명 완료된 문서 및 각종 정보(단, 서명 요청자 및 서명 참여자 전원의 동의가 있을 경우 삭제 가능)
2) 관련법령 및 회사의 개인정보 처리방침에서 정한 바에 따라 특별히 회원과 관계된 정보를 저장해야 할 때
3. 회원은 탈퇴 시점 이후 동일한 아이디로 다시 가입할 수 없습니다.

제15조 (이용제한 등)
1. 회사는 회원이 본 약관의 의무를 위반하거나 서비스의 정상적인 운영을 방해한 경우, 서비스 이용에 대한 경고, 일시정지, 계약해지로 단계적으로 제한할 수 있습니다.
2. 회사는 제1항의 규정에도 불구하고, 주민등록법을 위반한 명의도용 및 결제도용, 저작권법 및 컴퓨터프로그램보호법을 위반한 불법프로그램의 제공 및 운영방해, 정보통신망 이용촉진 및 정보보호 등에 관한 법률을 위반한 불법통신 및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련법령을 위반한 경우에는 즉시 계약을 해지 할 수 있습니다. 이에 따른 계약해지 시 서비스 이용을 통해 획득한 혜택 등은 모두 소멸되며, 회사는 이에 대해 별도로 보상하지 않습니다.
3. 회사가 위 제1항에 따라 회원의 서비스 이용을 제한하거나 계약을 해지하는 경우, 제한 조건 및 세부내용은 이용제한정책 등에서 정한 바에 따르며, 회사는 제9조에서 정한 방법으로 통지합니다.
4. 회원은 회사의 이용제한 조치 등에 대하여 회사가 정한 절차에 따라 이의신청을 할 수 있습니다. 회원의 이의 사유가 정당하다고 인정되는 경우 회사는 즉시 회원의 서비스 이용을 재개하여야 합니다.

제16조 (남용금지)
회사는 고의 또는 악의로 서명요청 작업과 기타 부가서비스를 반복 사용하여 피해를 끼치는 이용자의 사용을 중단시킬 수 있으며, 이에 대하여 법령상 특별한 규정이 없는 한 이용자에게 별도의 보상을 하지 않습니다.

&lt;제2장 유료 서비스 이용&gt;

제17조 (유료 서비스 이용 계약)
1. 회사는 다음 각 호의 유료 서비스를 제공하며, 회사의 사정, 기타 제반 여건에 따라 서비스 내용을 추가하거나 변경할 수 있습니다.
1) 판매 방식
- 포인트 충전 판매 : 회사가 제공하는 포인트 충전 상품을 회원이 직접 결제할 때, 상품별로 포인트를 차등하여 제공하는 방식을 말합니다.
2) 이용 방식
- 서명 요청 시 포인트 사용 : 회사가 제공하는 서비스를 통해 회원이 서명 요청을 진행할 때, 서명 요청 1건당 1포인트가 사용되는 방식을 말합니다.
포인트가 사용될 때는 이벤트로 받은 무료 포인트(가입축하 포인트, 관리자 선물 포인트)가 우선 사용됩니다.
2. 회원이 유/무선네트워크를 통해 서비스에 접속하여 제반 서비스를 이용하는 경우, 회원이 가입한 해당 통신사간에 체결된 통신 요금제에 의한 별도의 데이터 통화료가 발생합니다. 이 경우 발생하는 데이터 통화료는 회원과 해당 통신사간에 체결된 통신요금제에 따라 과금/청구/수납되므로, 데이터 통화료에 대해서는 회사는 어떠한 책임도 지지 않습니다.

제18조 (결제수단)
회원이 유료 서비스의 결제를 위하여 당사에서 운영하는 페이앱 서비스를 이용해 신용카드, 휴대폰 결제, 계좌이체, 네이버페이, 카카오페이 등의 다양한 결제수단으로 결제할 수 있습니다.

제19조 (유료 서비스 환불)
1. 회원이 결제한 포인트 충전 상품의 결제취소는 결제일로부터 15일 이내에 회사 고객센터에 요청 시 취소환불이 가능합니다.
단, 잔여 포인트가 충전 포인트보다 부족할 경우 환불이 불가합니다.
2. 결제한 포인트 충전 상품의 결제취소 시 환불 금액을 받는 방법은 사용자가 선택할 수 없으며, 기존에 결제한 수단으로 취소환불이 진행됩니다.
단, 계좌이체 결제건에 대해서는 환불 금액만큼 고객 계좌로 송금되며, 고객 입금 계좌 정보가 없을 경우 계좌 정보를 요청할 수 있습니다.

제20조 (유료 서비스 가액 변경)
회사는 운영상, 기술상의 필요에 따라 공급하는 유료 서비스 가액을 변경할 수 있으며, 제9조에서 정한 방법에 따라 회원에게 사전 공지합니다.

제21조 (과오금)
1. 회사는 유료 서비스 결제와 관련하여 과오금이 발생한 경우 이용대금의 결제와 동일한 방법으로 과오금 전액을 환불합니다. 다만, 동일한 방법으로 환불이 불가능할 때는 이를 사전에 고지합니다.
2. 회사의 귀책사유로 과오금이 발생한 경우 과오금 전액을 환불합니다. 단, 회원의 귀책사유로 과오금이 발생한 경우, 회사가 과오금을 환불하는데 소요되는 비용은 합리적인 범위 내에서 이용자가 부담하여야 하며, 회사는 해당 비용을 차감 후 과오금을 환불할 수 있습니다.
3. 회사는 회원이 과오금을 주장하며 환불을 요구하는 경우, 정당하게 유료 서비스 요금이 부과되었음을 입증하면 환불을 거부할 수 있습니다.
4. 회사는 과오금의 세부 환불절차 및 기타 사항에 대하여 다음과 같이 「콘텐츠이용자보호지침」이 정하는 바에 따릅니다.
1) 회사나 회원이 과오금의 발생 사실을 안 때에는 제9조의 회사가 제시한 방법에 따라 상대방에게 통보
2) 회사는 회원에게 환불에 필요한 정보를 요청(회원 성명, 결제증빙서류, 전화번호, 환불 요청 계좌 등)
3) 회원은 환불에 필요한 2호의 정보를 회사에 제공
4) 회사는 이용자의 정보 제공일로부터 7일 이내 환불 처리

&lt; 제3장 계약당사자의 의무 &gt;

제22조 (회사의 의무)
1. 회사는 본 약관 및 관련법령에서 금지하는 행위 및 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적인 서비스의 제공을 위하여 최선을 다하여 노력합니다.
2. 회사는 회원이 안전하게 서비스를 이용할 수 있도록 신용정보를 포함한 일체의 개인정보 보호를 위한 보안시스템을 갖추어야 하며 개인정보처리방침을 공시하고 준수합니다.
3. 회사는 회원으로부터 제기되는 의견이나 불만이 정당하다고 인정될 경우에는 합리적인 기간 내에 신속하게 처리하여야 합니다. 다만, 처리에 장기간이 소요되는 경우 회원에게 제9조의 방법으로 지체 사유를 안내하고 처리과정 및 처리결과를 전달합니다.
4. 회사는 이용계약의 체결, 계약사항의 변경 및 해지 등 이용자와의 계약관련 절차 및 내용 등에 있어 이용자에게 편의를 제공하도록 노력합니다.

제23조 (회원의 의무)
1. 회원은 회사에서 제공하는 서비스를 본래의 이용 목적 이외의 용도로 사용하거나 다음 각 호에 해당하는 행위를 해서는 안됩니다.
1) 가입신청 또는 정보 변경을 목적으로 회사에 개인정보 등록 시 실명이 아닌 정보 또는 다른 사람의 정보를 사용하거나 허위 사실을 기재하는 행위
2) 타인으로 가장하거나 타인과의 관계를 허위로 명시하는 행위, 다른 회원의 계정 및 비밀번호를 도용, 부정하게 사용하는 행위
3) 알려지거나 알려지지 않은 버그를 악용하여 서비스를 이용하는 행위
4) 회사 및 제3자의 명예를 훼손하거나 업무를 방해하거나 회사 및 제3자에게 손해를 가하는 행위
5) 회사의 지적재산권, 제3자의 지적재산권, 초상권 등 기타 권리를 침해하거나 회사의 승인을 받지 않고 다른 회원의 개인정보를 수집, 저장, 유포, 게시하는 행위
6) 제3자를 기망하여 이득을 취하거나 회사가 제공하는 서비스를 불건전하게 이용하거나 하여 제3자에게 피해를 주는 행위
7) 회사로부터 특별한 권리를 부여받지 않고 사이트를 변경하거나 사이트에 다른 프로그램을 추가 또는 삽입하거나 서버를 해킹, 역설계, 소스코드의 유출 및 변경, 별도의 서버를 구축하거나 웹사이트의 일부분을 임의로 변경 또는 도용하여 회사를 사칭하는 행위
8) 회사의 직원이나 운영자를 가장, 사칭하거나 또는 타인의 명의를 도용하여 문서를 등록하거나 메일을 발송하는 행위
9) 회사의 동의 없이 영리, 영업, 광고, 정치활동, 불법선거운동 등을 목적으로 서비스를 이용하는 행위
10) 기타 공공질서 및 미풍양속을 위반하거나 불법적, 부당한 행위 및 법령에 위배되는 행위
2. 회원은 회사의 공지사항 및 이용약관의 수정사항 등을 확인하고 이를 준수할 의무가 있으며 기타 회사의 업무에 방해되는 행위를 하여서는 안 됩니다.
3. 회원의 계정에 관한 관리 책임은 회원에게 있으며, 이를 제3자가 이용하도록 하여서는 안 됩니다.
4. 회사는 제1항, 제2항 및 다음 각 호의 어느 하나에 해당하는 행위의 구체적인 유형을 운영 정책에서 정할 수 있으며, 회원은 이를 준수할 의무가 있습니다.
1) 회원의 계정명, 비밀번호의 정함에 대한 제한
2) 기타 회원의 서비스 이용에 대한 본질적인 권리를 침해하지 않는 범위 내에서 회사가 운영상 필요하다고 인정되는 사항

&lt;제4장 기타&gt;

제24조 (최소 사용 환경)
1. 이용자는 아래 각 호에 해당하는 최소 사용 환경에서 서비스를 이용해야 합니다.
1) 운영체제 : Mac(Mavericks 이상), Windows7 이상
2) 브라우저 : Internet Explorer(11 이상), Edge(25.1 이상), Chrome(49 이상), Safari(9.0 이상) Opera(37 이상), Firefox(43.0 이상)
3) 쿠키 : 모든 쿠키 허용
2. 최소 사용 환경을 충족하지 못하는 환경에서 서비스를 이용할 경우, 이와 발생하는 문제에 대해 회사는 책임지지 않습니다.

제25조 (손해배상)
1. 회사가 제공하는 유료 서비스의 하자 등에 의하여 회원에게 피해가 발생한 경우, 회원에 대한 피해보상의 기준, 범위, 방법 및 절차에 관한 사항은 콘텐츠이용자보호지침에 따릅니다.
2. 회원이 본 약관의 의무를 위반함으로 인하여 회사에 손해를 입힌 경우 또는 회원이 서비스의 이용과 관련하여 회사에 손해를 입힌 경우 회원은 회사에 대하여 손해를 배상하여야 합니다.
3. 회원이 서비스를 이용함에 있어 행한 불법행위 또는 본 약관을 위반한 행위로 회사가 당해 이용자 외의 제3자로부터 손해배상청구 또는 소송 등 각종 이의제기를 받는 경우 당해 회원은 자신의 책임과 비용으로 회사를 면책시켜야 하며, 회사가 면책되지 못한 경우 당해 이용자는 그로 인하여 회사에 발생한 모든 손해를 배상할 책임이 있습니다.

제26조 (책임의 한계)
1. 회사는 천재지변 또는 국가 정책 등 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.
2. 회사는 회원의 귀책사유로 인한 서비스 중지 또는 이용의 장애, 기간통신사업자가 전기통신서비스를 중지하거나 정상적으로 제공하지 아니하여 회원에게 손해가 발생한 경우에는 책임이 면제됩니다.
3. 회사는 회원이 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다.
4. 회사는 회원 간 또는 회원과 제3자 상호간에 서비스를 매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다.
5. 회사는 무료로 제공되는 서비스 이용과 관련하여 관련법령에 특별한 규정이 없는 한 책임을 지지 않습니다.

제27조 (준거법 및 재판관할)
1. 회사와 회원 간 제기된 소송은 대한민국법을 준거법으로 합니다.
2. 회사와 회원간 발생한 분쟁에 대해 소송이 제기될 경우 회사의 본사 소재지를 관할하는 법원을 전속 관할법원으로 합니다.

제28조 (전자서명 이용약관의 적용)
회원이 서명 요청자로서 서명 참여자에게 전자서명을 요청할 경우 전자서명 이용약관이 함께 적용됩니다.

부칙

본 약관은 2022년 07월 01일부터 적용됩니다.
          </textarea>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { reactive, toRefs } from 'vue'

export default {
  setup () {
    const state = reactive({
      isShow: false
    })
    const show = () => {
      state.isShow = true
    }
    const hide = () => {
      state.isShow = false
    }
    const onCancel = () => {
      hide()
    }
    return {
      ...toRefs(state),
      show,
      hide,
      onCancel
    }
  }
}
</script>
