import request from 'utils/request'

export const getPointBalance = () => {
  return request.get('/point/balance')
}

export const getPointProduct = () => {
  return request.get('/point/product')
}

export const charge = productId => {
  return request.post('/point/charge', { productId })
}

export const getChargeHistory = (pageNum, pageSize) => {
  return request.get('/point/charge/history', {
    params: {
      page: pageNum,
      limit: pageSize
    }
  })
}

export const getUsageHistory = (pageNum, pageSize) => {
  return request.get('/point/usage/history', {
    params: {
      page: pageNum,
      limit: pageSize
    }
  })
}

export const registerPaymentMethod = (params) => {
  return request.post('/point/point-payment-method', params)
}

export const getPointPaymentMethod = () => {
  return request.get('/point/point-payment-method')
}

export const removePointPaymentMethod = (id) => {
  return request.delete(`/point/point-payment-method/${id}`)
}

export const subscribePointAutoCharge = (params) => {
  return request.post('/point/subscription', params)
}

export const getSubscriptionInfo = () => {
  return request.get('/point/subscription')
}

export const modifyEmailRecipient = (params) => {
  return request.put('/point/subscription/email', params)
}

export const changeSubscription = (params) => {
  return request.put('/point/subscription', params)
}

export const cancelSubscription = () => {
  return request.delete('point/subscription')
}

export const autoCharge = () => {
  return request.post('/point/charge/subscription')
}

export const restorePointPaymentMethod = () => {
  return request.put('point/point-payment-method/restoration')
}
