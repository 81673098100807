import {
  BackTop,
  Button,
  Calendar,
  Card,
  Carousel,
  Checkbox,
  Col,
  Collapse,
  ConfigProvider,
  DatePicker,
  Divider,
  Dropdown,
  Empty,
  Form,
  Icon,
  Input,
  InputNumber,
  Layout,
  List,
  Menu,
  Modal,
  Popover,
  Progress,
  Radio,
  Result,
  Row,
  Select,
  Slider,
  Space,
  Spin,
  Statistic,
  Steps,
  Switch,
  Table,
  Tabs,
  Tag,
  TimePicker,
  Tooltip,
  Tree,
  Upload
} from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'

export function setupAntd (app) {
  app.use(ConfigProvider)
    .use(Form)
    .use(Input)
    .use(Modal)
    .use(Table)
    .use(Menu)
    .use(Card)
    .use(Checkbox)
    .use(Radio)
    .use(Button)
    .use(Select)
    .use(Layout)
    .use(Col)
    .use(Row)
    .use(Dropdown)
    .use(Spin)
    .use(Divider)
    .use(Steps)
    .use(Upload)
    .use(Tabs)
    .use(Spin)
    .use(Switch)
    .use(Statistic)
    .use(Slider)
    .use(Tooltip)
    .use(Popover)
    .use(InputNumber)
    .use(Result)
    .use(Space)
    .use(Tree)
    .use(Icon)
    .use(Progress)
    .use(Carousel)
    .use(DatePicker)
    .use(TimePicker)
    .use(List)
    .use(Tag)
    .use(BackTop)
    .use(Collapse)
    .use(Calendar)
    .use(Empty)
}
