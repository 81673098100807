import { createRouter, createWebHistory } from 'vue-router'
import UserLayout from 'components/layout/UserLayout'
import DefaultLayout from 'components/layout/DefaultLayout'
import SignLayout from 'components/layout/SignLayout'
import PdfPreviewLayout from 'components/layout/PdfPreviewLayout'
import DeveloperLayout from 'components/layout/DeveloperLayout'
import createStorage from 'utils/storage'
import { userInfo, userToken } from 'config/config'
import { clearUserInfo, signFromLink } from 'utils/helper'
import { getUserInfo } from 'api/user'
import { Modal } from 'ant-design-vue'
import store from '@/store/index'
import { trackRouter } from 'vue-gtag-next'

/*
점검 & 업데이트시 행동
1. SWITCH_INSPECTION = true로 설정, 점검페이지 내용을 실제 점검시간으로 반여
2. 해당 내용 그대로 개발서버에 업데이트
3. Front-end시스템은 유지하고, Back-end만 shutdown
*/
const SWITCH_INSPECTION = false
let isDeveloper = null
if (store.state.user.userInfo) {
  isDeveloper = store.state.user.userInfo.isDeveloper
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    children: [
      {
        path: '/documents',
        name: 'Index',
        meta: { title: '내 문서' },
        component: () => import('views/documents/Index')
      },
      {
        path: '/payments',
        name: 'Payments',
        meta: { title: '결제 관리' },
        component: () => import('views/payments/Index')
      },
      {
        path: '/orgDocuments',
        name: 'OrgIndex',
        meta: { title: '조직 문서' },
        component: () => import('views/documents/Index')
      },
      /* {
        path: '/orgOwnerDocuments',
        name: 'OrgOwnerIndex',
        meta: {
          title: 'documents.header.orgOwnerIndex',
          type: 'organization',
          isOwner: true
        },
        component: () => import('views/documents/Index')
      },
      {
        path: '/teamDocuments',
        name: 'TeamIndex',
        meta: {
          title: 'documents.header.teamIndex',
          type: 'team',
          isOwner: false,
          parentMenuName: 'TeamIndex'
        },
        component: () => import('views/documents/Index')
      },
      {
        path: '/teamOwnerDocuments',
        name: 'TeamOwnerIndex',
        meta: {
          title: 'documents.header.teamOwnerIndex',
          type: 'team',
          isOwner: true,
          parentMenuName: 'TeamIndex'
        },
        component: () => import('views/documents/Index')
      },
      {
        path: '/teamTemplate',
        name: 'TeamTemplate',
        meta: {
          title: 'documents.header.teamTemplate',
          type: 'team',
          isOwner: false,
          parentMenuName: 'TeamIndex'
        },
        component: () => import('@/views/template/index.vue')
      },
      {
        path: '/docuEdit',
        name: 'EditingDocuments',
        redirect: { name: 'Index' }
        // meta: { title: 'documents.header.docuEdit' },
        // component: () => import('@/views/docuEdit/index.vue')
      }, */
      {
        path: '/template',
        name: 'Template',
        meta: { title: '템플릿' },
        component: () => import('@/views/template/index.vue')
      },
      {
        path: '/bulkTransmit',
        name: 'BulkTransmit',
        meta: { title: '대량전송' },
        component: () => import('@/views/bulkTransmit/index.vue')
      },
      {
        path: '/signStamp',
        name: 'SignStamp',
        meta: { title: '사인/도장' },
        component: () => import('@/views/signStamp/index.vue')
      },
      {
        path: '/point',
        name: 'Point',
        meta: { title: '포인트 관리' },
        component: () => import('@/views/point/index.vue')
      },
      {
        path: '/settings',
        name: 'Settings',
        meta: { title: '설정' },
        component: () => import('views/settings/index')
      },
      {
        path: '/subaccount',
        name: 'Subaccount',
        meta: { title: '부계정 관리' },
        component: () => import('views/subAccountManagement/index')
      }
    ]
  },
  {
    path: '/bulkTransmit/excel/:id',
    name: 'bulkTransmitExcel',
    component: () => import('@/views/bulkTransmit/modules/EditExcel.vue')
  },
  {
    path: '/bulkTransmit/detail/:id',
    name: 'bulkTransmitDetail',
    component: () => import('@/views/bulkTransmit/modules/detail.vue')
  },
  {
    path: '/user',
    name: 'User',
    component: UserLayout,
    children: [
      {
        path: '/user/login',
        name: 'Login',
        component: () => import('views/user/Login')
      },
      {
        path: '/user/oauth/login',
        name: 'OauthLogin',
        component: () => import('views/user/OauthLogin')
      },
      /*
      {
        path: '/user/join',
        name: 'Join',
        component: () => import('views/user/Join')
      },
      {
        path: '/user/forget',
        name: 'Forget',
        component: () => import('views/user/Forget')
      },
      {
        path: '/user/forget/id/result',
        name: 'ForgetIdResult',
        component: () => import('views/user/ForgetIdResult')
      },
      {
        path: '/user/forget/password/result',
        name: 'ForgetPasswordResult',
        component: () => import('views/user/ForgetPasswordResult')
      },
      {
        path: '/user/resetpassword',
        name: 'ResetPassword',
        component: () => import('views/user/ResetPassword')
      },
      */
      {
        path: '/user/logout',
        name: 'logout',
        component: () => import('views/user/Logout')
      },
      {
        path: '/user/email/authentication',
        name: 'EmailAuthentication',
        component: () => import('views/user/Authentication')
      }
    ]
  },
  {
    path: '/signing',
    name: 'Signing',
    component: SignLayout,
    children: [
      {
        path: '/signCreating/progress/:documentId?',
        name: 'SignCreating',
        component: () => import('views/signCreating/progress')
      },
      {
        path: '/signCreating/mobile/progress/:documentId?',
        name: 'MobileSignCreating',
        component: () => import('views/signCreating/mobile/progress')
      },
      {
        path: '/signing/progress/:documentId',
        name: 'SigningProgress',
        component: () => import('views/signing/progress')
      },
      {
        path: '/signing/payment',
        name: 'SignPayment',
        component: () => import('views/signing/payment')
      },
      {
        path: '/signing/complete',
        name: 'SigningComplete',
        component: () => import('views/signing/Complete')
      },
      {
        path: '/signing/authentication/:documentId',
        name: 'Authentication',
        component: () => import('views/signing/Authentication')
      },
      {
        path: '/directLink/:encryptedLink',
        name: 'DirectSignLink',
        component: () => import('views/signing/DirectSignLink')
      },
      {
        path: '/otp',
        name: 'OTP',
        component: () => import('views/signing/OTP')
      }
    ]
  },
  {
    path: '/participant',
    name: 'Participant',
    component: () => import('views/signing/Participant')
  },
  {
    path: '/contracts',
    name: 'Contracts',
    component: PdfPreviewLayout,
    redirect: { name: 'Contracts' },
    children: [
      {
        path: '/contracts/read/:documentId',
        name: 'Contract',
        component: () => import('views/contracts/Preview')
      }
    ]
  },
  /*   {
    path: '/docuEditting/:editingDocumentId',
    redirect: { name: 'Index' }
    // name: 'DocEditting',
    // component: () => import('views/documentEdit/docEdit')
  }, */
  {
    path: '/templateEditing/:templateId?',
    name: 'TemplateEditting',
    component: () => import('views/template/templateEdit')
  },
  {
    path: '/viewDoc/:documentId?',
    name: 'viewDoc',
    component: () => import('views/bulkTransmit/modules/ViewDoc')
  },
  {
    path: '/testview',
    name: 'Testview',
    component: () => import('views/testview/Index')
  },
  {
    path: '/pdfViewer/:documentId?',
    name: 'pdfViewer',
    component: () => import('views/pdfViewer/Index')
  },
  {
    path: '/payment/complete',
    name: 'PaymentComplete',
    component: () => import('views/signing/PaymentComplete')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('views/errors/404')
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('views/errors/error')
  },
  {
    path: '/inspection',
    name: 'Inspection',
    component: () => import('views/errors/inspection')
  },
  {
    path: '/short/:index',
    name: 'RedirectShortURL',
    component: () => import('views/shortUrl')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404'
  },
  {
    path: '/embedded/:index',
    name: 'Embedded',
    component: () => import('views/embedded/index')
  },
  // {
  //   path: '/test',
  //   name: 'Test',
  //   component: () => import('views/test')
  // },
  {
    path: '/developer',
    name: 'Developer',
    component: DeveloperLayout,
    children: [
      {
        path: '/developer',
        name: 'Dev-Index',
        meta: { title: 'developer.index.title' },
        component: () => import('views/developer/index')
      },
      {
        path: '/developer/error-code',
        name: 'Dev-ResponseCode',
        meta: { title: 'developer.response_code.title' },
        component: () => import('views/developer/responseCode')
      },
      {
        path: '/developer/auth',
        name: 'Dev-Auth',
        meta: { title: 'developer.auth.title' },
        component: () => import('views/developer/auth')
      },
      {
        path: '/developer/sign-request',
        name: 'Dev-SignRequest',
        meta: { title: 'developer.sign_request.title' },
        component: () => import('views/developer/signRequest')
      },
      {
        path: '/developer/webhook',
        name: 'Dev-Webhook',
        meta: { title: 'developer.webhook.title' },
        component: () => import('views/developer/webhook')
      },
      {
        path: '/developer/embedding',
        name: 'Dev-Embedding',
        meta: { title: 'developer.embedding.title' },
        component: () => import('views/developer/embedding')
      },
      {
        path: '/developer/oauth',
        name: 'Dev-Oauth',
        meta: { title: 'developer.oauth.title' },
        component: () => import('views/developer/oauth')
      },
      {
        path: '/developer/updatelog',
        name: 'Dev-UpdateLog',
        meta: { title: 'developer.update_log.title' },
        component: () => import('views/developer/updateLog')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

trackRouter(router)

const storage = createStorage()

const filterRoutes = [
  'Login', 'OauthLogin', 'Participant', 'Authentication',
  'SignPayment', 'SigningComplete', 'PaymentComplete', 'Testview',
  'EmailAuthentication', 'DirectSignLink', 'SigningProgress', 'SigningComplete',
  'RedirectShortURL', 'Inspection', '404', 'error',
  'Embedded', 'OTP'
]

const realPush = (to, from, next) => {
  const token = storage.get(userToken)
  const userInfoData = storage.get(userInfo)

  if (to.path === '/') {
    next({ name: 'Index' })
  }
  if (to.name === 'Participant') {
    if (to.query.di === 'test' && to.query.pi === 'test' && to.query.lk === 'test') {
      next({ name: 'Testview' })
    } else {
      const sessionStorage = createStorage('sessionStorage')
      sessionStorage.set('LINK_KEY', to.query.lk)
      sessionStorage.set('SIGN_METHOD_TYPE', to.query.smt)
    }
  }

  const [isFromLink] = signFromLink()
  if (isFromLink) {
    next()
  } else if (token) {
    if (to.name === 'Login') {
      userInfoData && userInfoData.organization ? next({ name: 'OrgIndex' }) : next({ name: 'Index' })
    }
    next()
  } else {
    if (to.path.includes('/developer') || filterRoutes.includes(to.name)) {
      next()
    } else {
      next({ name: 'Login' })
    }
  }
}

router.beforeEach((to, from, next) => {
  if (SWITCH_INSPECTION && to.name !== 'Inspection' && to.name !== 'Login' && !isDeveloper) {
    next({ name: 'Inspection' })
  } else if (!SWITCH_INSPECTION && to.name === 'Inspection') {
    next({ name: 'Home' })
  } else {
    const token = storage.get(userToken)
    const [isFromLink] = signFromLink()

    if ((token && !isFromLink) || (to.path === '/' && to.query.accessToken !== undefined && to.query.to !== null)) {
      if (to.query.accessToken) {
        store.commit('setToken', to.query.accessToken)
        if (to.query.refreshToken) {
          store.commit('setRefreshToken', to.query.refreshToken)
        }
      }
      getUserInfo()
        .then(res => {
          const {
            result,
            code
          } = res
          if (code === 0) {
            storage.set(userInfo, result)
            store.state.user.userInfo = result
          } else {
            storage.set(userInfo, {})
          }
          realPush(to, from, next)
        })
        .catch(error => {
          const msg = error.msg || '회원정보 조회 실패'
          Modal.error({ content: msg })
          clearUserInfo()
          next({ name: 'Login' })
        })
    } else {
      realPush(to, from, next)
    }
  }
})

export default router
