import { createI18n } from 'vue-i18n'
import koKR from 'locale/ko-KR'
import enUS from 'locale/en-US'
import store from '@/store'

const messages = {
  'ko-KR': { ...koKR },
  'en-US': { ...enUS }
}
export const i18n = createI18n({
  locale: store.state.app.locale,
  fallbackLocale: 'en-US',
  messages
})

export function setupI18n (app) {
  app.use(i18n)
}

export function i18nt (key) {
  return i18n.global.t(`${key}`)
}
