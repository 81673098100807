<template>
  <a-modal
    :bodyStyle="{ padding: 0 }"
    :closable="false"
    :footer="null"
    :keyboard="false"
    :maskClosable="false"
    :visible="visible"
    :width="480"
    class="ucs-modal"
  >
    <div class="modal-header">
      <slot name="title">유캔싸인 이용 동의 안내</slot>
    </div>
    <div class="modal-content">
      유캔싸인 관리자 최초 접속시 1회 이용 동의를 받습니다.<br/>
      동의하지 않으실 경우 유캔싸인 관리자 접속이 불가함을 알려드립니다<br/>
      <br/>
      <a-divider>이용약관 및 개인정보수집/이용안내</a-divider>
      <ul class="agree-list">
        <li>
          [필수] 유캔싸인 이용약관
          <button data-target="#agreement1" data-toggle="modal" @click="viewPolicy">보기</button>
        </li>
        <li>
          [필수] 개인정보 처리방침
          <button data-target="#agreement2" data-toggle="modal" @click="viewPolicy">보기</button>
        </li>
        <li>
          만 14세 미만은 서비스 이용이 불가합니다.
        </li>
        <a-divider/>
        <li style="margin-bottom:2rem">
          <a-checkbox v-model:checked="agree">
            위 내용에 모두 동의하고 유캔싸인에 접속합니다.
          </a-checkbox>
        </li>
      </ul>
      <a-button :disabled="!agree" :loading="submitting" @click="submitPolicyAgreement">확인</a-button>
    </div>
  </a-modal>
  <TermsAndConditions ref="agreement1" width="760px"/>
  <PrivacyPolicy ref="agreement2" width="760px"/>
</template>

<script>
import { defineComponent, reactive, ref, toRefs } from 'vue'
import { setNotice as submit } from 'api/user'
import TermsAndConditions from './TermsAndConditions.vue'
import PrivacyPolicy from './PrivacyPolicy.vue'
import { Modal } from 'ant-design-vue'
import { useStore } from 'vuex'

export default defineComponent({
  components: {
    TermsAndConditions,
    PrivacyPolicy
  },

  emits: ['confirm'],
  setup (props, { emit }) {
    const store = useStore()
    const agreement1 = ref(null)
    const agreement2 = ref(null)
    const state = reactive({
      visible: false,
      agree: false,
      submitting: false
    })

    const show = () => {
      state.visible = true
    }

    const hide = () => {
      state.visible = false
    }

    const viewPolicy = (obj) => {
      const value = obj.currentTarget.dataset.target
      if (value === '#agreement1') {
        agreement1.value.show()
      } else if (value === '#agreement2') {
        agreement2.value.show()
      }
    }

    const submitPolicyAgreement = () => {
      state.submitting = true
      if (state.agree === true) {
        submit({ type: 'policy' })
          .then(() => {
            state.submitting = false
            store.dispatch('userInfo').then(() => {
              emit('confirm')
              hide()
            })
          })
          .catch((err) => {
            Modal.error({ content: err.msg })
            state.submitting = false
          })
      }
    }

    return {
      ...toRefs(state),
      agreement1,
      agreement2,
      show,
      hide,
      viewPolicy,
      submitPolicyAgreement,
      submit
    }
  }
})
</script>

<style lang="less" scoped>
.ucs-modal {
  font-size: 16px;

  .modal-header {
    padding: 2rem;
    font-size: 1.7rem;
    font-weight: 500;
    line-height: 2.2rem;
    background: linear-gradient(251deg, #5FAEF9 0%, #1890ff 100%);
    color: #fff;
    padding: 2rem !important;

    .modal-close {
      margin: 24px;
    }
  }

  .modal-content {
    border: none;
    padding: 2rem;
    text-align: center;
  }

  .agree-list {
    display: block;

    li {
      width: 100%;
      margin-left: 0
    }
  }
}
</style>
