<template>
  <a-modal class="team-change-modal"
           v-model:visible="isShow"
           :bodyStyle="{ padding: 0, fontSize: '16px' }"
           :width="380"
           :closable="false"
           :footer="null"
           :destroyOnClose="true">
    <div class="modal-header bg-gray">
      <h2 style="margin-top:-0.2rem; padding-bottom:1rem">
        팀 선택
        <a-tooltip placement="right" :color="'#FFF'" overlayClassName="signer-tooltip">
          <button type="button" class="btn-info">?</button>
          <template #title>
            <p class="content">
              조직 내 생성된 팀을 선택할 수 있어요.
              선택한 팀에 따라 해당팀에 저장되어 있는 문서, 템플릿 정보를 확인할 수 있어요.
            </p>
          </template>
        </a-tooltip>
      </h2>
      <span role="button" class="modal-close" @click="isShow=false">닫기</span>
    </div>
    <a-spin :spinning="isLoading">
      <div class="modal-content team-select">
        <div class="modal-body">
          <h3>팀 이름</h3>
          <!-- <ul class="deb teams-ul">
            <li v-for="team in teams" :key="team.teamId">
              <label :for="team.teamId"
                ><span>{{ team.name }} <i class="text-red" v-if="myTeamTip(team)"> (내 팀) </i></span>
                <input type="radio" name="team" :id="team.teamId" :value="team.teamId" :checked="selectedTeamId === team.teamId" @change="e => selectedTeamId = e.target.value" />
              </label>
            </li>
          </ul> -->
          <TeamList :listType="TeamListType.AssignObject" style="max-height: 400px; overflow: auto; border: solid 1px #d9d9d9; border-radius: 2px" v-model:selectedTeamId="selectedTeamId"
                    :targetTeamId="curUserTeamId" @onSelectedTeam="onSelectedTeam"></TeamList>

          <div class="btn-row">
            <a-button class="btn" @click="isShow=false">취소</a-button>
            <a-button type="primary" class="btn" @click="confirm">완료</a-button>
          </div>
        </div>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import { reactive, ref, toRefs, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { getTeamId } from 'utils/helper'
import TeamList, { TeamListType } from '@/views/teams/teamList.vue'

export default {
  components: { TeamList },
  setup (props, { emit }) {
    const store = useStore()

    const state = reactive({
      isShow: false,
      teams: [],
      selectedTeamId: getTeamId(),
      selectedTeam: null,
      isLoading: false,
      curUserTeamId: ''
    })
    const modal = ref(null)
    const listRef = ref(null)
    const show = () => {
      state.isShow = true
      state.selectedTeamId = getTeamId()
      state.curUserTeamId = store.state.user.userInfo.organization.teamId
      if (listRef.value) listRef.value.refresh()
    }
    const hide = () => {
      state.isShow = false
    }
    const confirm = () => {
      if (state.selectedTeamId === getTeamId()) {
        hide()
        return
      }
      store.dispatch('setTeam', state.selectedTeam)
      emit('teamSelected', state.selectedTeam)
      store.dispatch('setFolder', null)
      hide()
    }
    watchEffect(() => {
      state.teams = store.state.app.teams
    })

    const onSelectedTeam = team => {
      state.selectedTeam = team
    }

    return {
      show,
      hide,
      modal,
      confirm,
      ...toRefs(state),
      TeamListType,
      onSelectedTeam
    }
  }
}
</script>

<style lang="less" scoped>
.team-change-modal {
  .modal-header {
    padding: 2rem;
    border: none;
  }

  .modal-close {
    margin: 24px;
  }

  .modal-content {
    border: none;
  }

  .btn-row {
    margin: 20px 0 0 0;
    display: flex;
    justify-content: space-between;
  }

  .btn {
    width: 48%;
  }

  .teams-ul {
    height: 300px;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none
    }
  }
}
</style>
