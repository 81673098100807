import { currentPdf } from 'config/config'
import createStorage from 'utils/storage'
import { genKey } from 'utils/helper'
import dayjs from 'dayjs'
import { validDate } from 'utils/validate'

const storage = createStorage()
const documents = {
  state: {
    signers: [],
    currentPdf: storage.get(currentPdf, {
      name: '',
      pdfUrl: '',
      password: ''
    }),
    documentInfo: {},
    pdfData: [],
    isAllSignFilled: false,
    isRequesterSigned: false,
    bulkRecordData: null,
    pay: {},
    fieldGroup: [],
    undoHistoryStorage: [],
    redoHistoryStorage: [],
    fieldClipBoard: [],
    lockPushHistory: false
  },
  getters: {
    getClipBoard (state) {
      return state.fieldClipBoard
    },
    undoTask (state) {
      if (state.undoHistoryStorage.length > 1) {
        state.redoHistoryStorage.push(refactoringData(state.undoHistoryStorage.pop()))
        return refactoringData(state.undoHistoryStorage.slice(-1)[0])
      } else {
        return null
      }
    },
    redoTask (state) {
      if (state.redoHistoryStorage.length > 0) {
        const data = refactoringData(state.redoHistoryStorage.pop())
        state.undoHistoryStorage.push(data)
        return data
      } else {
        return null
      }
    }
  },
  mutations: {
    SET_SIGNERS (state, signers) {
      state.signers = signers
    },
    SET_CURRENT_PDF (state, value) {
      storage.set(currentPdf, value)
      state.currentPdf = value
    },
    SET_DOCUMENT_INFO (state, documentInfo) {
      state.documentInfo = documentInfo
    },
    SET_PDF_DATA (state, pdfData) {
      state.pdfData = pdfData
    },
    SET_IS_All_SIGN_FILLED (state, flag) {
      state.isAllSignFilled = flag
    },
    SET_IS_REQUESTER_SIGNED (state, flag) {
      state.isRequesterSigned = flag
    },
    SET_BULK_RECORD (state, record) {
      state.bulkRecordData = record
    },
    SET_PAY (state, value) {
      state.pay = value
    },
    SET_FIELD_GROUP (state, value) {
      state.fieldGroup = value
    },
    PUSH_HISTORY_STORAGE (state) {
      if (state.lockPushHistory) return
      state.lockPushHistory = true
      setTimeout(() => {
        while (state.undoHistoryStorage.length >= 50) {
          state.undoHistoryStorage.shift()
        }
        const data = {
          pdfData: state.pdfData,
          fieldGroup: state.fieldGroup
        }
        state.undoHistoryStorage.push(refactoringData(data))
        state.redoHistoryStorage.splice(0)
        setTimeout(() => {
          state.lockPushHistory = false
        }, 100)
      }, 100)
    },
    COPY_FIELDS (state, fields) {
      state.fieldClipBoard = fields
    },
    RESET_EDIT_DATA (state) {
      state.fieldGroup = []
      state.undoHistoryStorage = []
      state.redoHistoryStorage = []
      state.fieldClipBoard = []
    }
  },
  actions: {
    updateSigners ({ commit }, signers) {
      commit('SET_SIGNERS', signers)
    },
    setCurrentPdf ({ commit }, currentPdf) {
      commit('SET_CURRENT_PDF', currentPdf)
    },
    setDocumentInfo ({ commit }, documentInfo) {
      commit('SET_DOCUMENT_INFO', documentInfo)
    },
    updateSigner ({
      commit,
      state
    }, signer) {
      const signers = state.signers
      const { dragKey } = signer
      const index = signers.findIndex(item => item.dragKey === dragKey)
      signers[index] = signer
      commit('SET_SIGNERS', signers)
    },
    setPdfData ({ commit }, pdfData) {
      commit('SET_PDF_DATA', pdfData)
    },
    setIsAllSignFilled ({ commit }, flag) {
      commit('SET_IS_All_SIGN_FILLED', flag)
    },
    setIsRequesterSigned ({ commit }, flag) {
      commit('SET_IS_REQUESTER_SIGNED', flag)
    },
    setBulkRecord ({ commit }, bulkData) {
      commit('SET_BULK_RECORD', bulkData)
    },
    setPay ({ commit }, value) {
      commit('SET_PAY', value)
    },
    setFieldGroup ({ commit }, value) {
      commit('SET_FIELD_GROUP', value)
    },
    pushHistoryStorage ({ commit }) {
      commit('PUSH_HISTORY_STORAGE')
    },
    copyFields ({ commit }, fields) {
      commit('COPY_FIELDS', fields)
    },
    resetEditData ({ commit }) {
      commit('RESET_EDIT_DATA')
    }
  }
}

export default documents

const refactoringData = data => {
  const result = JSON.parse(JSON.stringify(data))
  result.pdfData.forEach(page => {
    page.requesterFields.forEach(item => {
      item.key = `${item.type}-${genKey()}`
      item.dragKey = item.key
    })
    page.fields.forEach(item => {
      item.key = `${item.type}-${genKey()}`
      item.dragKey = item.key
      if (item.type === 'date') {
        if (item.textValueText !== null && validDate(item.textValueText)) {
          const dateArray = item.textValueText.split(/[-/년월일]/)
          const year = parseInt(dateArray[0])
          const month = parseInt(dateArray[1]) - 1
          const day = parseInt(dateArray[2])
          item.dateValue = dayjs(new Date(year, month, day))
        }
      }
    })
  })
  return result
}
