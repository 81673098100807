/**
 * @param {string} email
 * @returns {Boolean}
 */
import dayjs from 'dayjs'

export function validEmail (email) {
  const reg = /^[\w-.]+@([\w-]+\.)+[\w-]{2,10}$/
  return reg.test(email)
}

/**
 * @param {string} phone
 * @returns {Boolean}
 */
export function validMobilePhone (phone) {
  const reg = /^([0-9]{3})+-([0-9]{4})+-([0-9]{4})$/
  return reg.test(phone)
}

export function validPureMobilePhone (phone) {
  const reg = /^([0-9]{11})$/
  return reg.test(phone)
}

/**
 * @param {string} id
 * @returns {Boolean}
 */
export function validId (id) {
  const reg = /^([a-z]+([a-z0-9]{3,}))$/
  return reg.test(id)
}

/**
 * @param {string} password
 * @returns {Boolean}
 */
export function validPassword (password) {
  const reg = /^(?=.*\d)(?=.*[a-zA-z]).{8,}$/
  return reg.test(password)
}

export function validNumber (number) {
  const reg = /^[0-9]+$/
  return reg.test(number)
}

export function validAmericanDate (date) {
  const reg = /^\d{1,2}\/\d{1,2}\/\d{2,4}$/
  return reg.test(date)
}

export function validDate (date) {
  const reg = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$|^\d{4}\/(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])$|^\d{4}년 (0[1-9]|1[012])월 (0[1-9]|[12][0-9]|3[01])일$/
  return reg.test(date)
}

export function validDayjsDate (date) {
  const parseDate = dayjs(date)
  return parseDate.isValid()
}

export function validAuthName (name) {
  const reg = /^[가-힣A-Za-z]{2,10}$/
  return reg.test(name)
}
