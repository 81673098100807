import request from 'utils/request'
import md5 from 'md5'

export const login = (id, password) => {
  return request.post(
    '/user/oauth/payapp/token',
    {
      grantType: 'login',
      loginId: id,
      password: password
    },
    { config: { isShowMessage: false } }
  )
}

export const refreshToken = (accessToken, refreshToken) => {
  return request.post(
    '/user/oauth/payapp/token',
    {
      grantType: 'refresh',
      accessToken: accessToken,
      refreshToken: refreshToken
    },
    { config: { isShowMessage: false } }
  )
}

export const sublogin = (id, password) => {
  return request.post(
    '/user/oauth/token',
    {
      loginId: id,
      password: password
    }
  )
}

export const requestSmsCode = (mobilePhoneNumber) => {
  var param = null
  if (process.env.NODE_ENV === 'production') {
    param = { phone: mobilePhoneNumber }
  } else {
    param = {
      code: 'ucansign', // 因为我们手机收不到验证码，所以这里传一个code=ucansign，带上这个参数，验证码会返回到response末尾
      phone: mobilePhoneNumber
    }
  }
  return request.post('/user/requestSmsCode', param)
}

export const verifySmsCode = (mobilePhoneNumber, code) => {
  return request.post(
    '/user/verifySmsCode',
    {
      code: code,
      phone: mobilePhoneNumber
    }
  )
}

export const verifyLoginId = (id) => {
  return request.post(
    '/user/loginId/check',
    {
      loginId: id
    }
  )
}

export const requestResetPassword = (postData) => {
  return request.post('user/password/reset/token', postData, {
    config: {
      isShowMessage: false
    }
  })
}

export const register = (phone, loginId, password, email, name, isBuisness, companyName, companyRegistrationNumber) => {
  var param = null
  if (password !== undefined) {
    password = md5(password)
  }
  if (isBuisness) {
    param = {
      phone: phone,
      loginId: loginId,
      password: password,
      email: email,
      name: name,
      type: 'COMPANY',
      companyName: companyName,
      companyRegistrationNumber: companyRegistrationNumber
    }
  } else {
    param = {
      phone: phone,
      loginId: loginId,
      password: password,
      email: email,
      name: name,
      type: 'PERSONAL'
    }
  }
  return request.post('user', param)
}

export const findLoginId = (name, email) => {
  return request.post(
    'user/loginId/find',
    {
      email: email,
      name: name
    }
  )
}

export const findPassword = (loginId, email) => {
  return request.put(
    'user/password/reset/force',
    {
      email: email,
      loginId: loginId
    }
  )
}

export const resetPassword = (oldPassword, newPassword) => {
  return request.put(
    'user/password/reset',
    {
      newPassword: md5(newPassword),
      oldPassword: md5(oldPassword)
    }
  )
}

export const validateResetToken = (postData) => {
  return request.post('user/email/authentication', postData, {
    config: {}
  })
}

export const getUserInfo = () => {
  return request.get(
    'user',
    { config: { isShowMessage: false } }
  )
}

export const checkDuplicateEmail = (email) => {
  return request.post('user/email/duplicate',
    {
      email: email
    },
    { config: { isShowMessage: false } })
}

export const changeEmail = (loginId, type, email) => {
  return request.put('user/email',
    {
      loginId: loginId,
      type: type,
      email: email
    },
    { config: { isShowMessage: false } })
}

export const requestValidateEmail = (loginId, type, email, clientId, redirectUri, state) => {
  return request.post('user/email/authentication/request',
    {
      loginId: loginId,
      type: type,
      email: email,
      clientId: clientId,
      redirectUri: redirectUri,
      state: state
    },
    { config: { isShowMessage: false } })
}

export const validateEmail = (token) => {
  return request.post('user/email/authentication',
    {
      token: token
    },
    { config: { isShowMessage: false } })
}

export const logout = () => {
  return request.delete(
    'user/oauth/token',
    { config: { isShowMessage: false } }
  )
}

export const setUserName = (name) => {
  return request.put(`user/name?name=${name}`, { config: { isShowMessage: false } })
}

export const setLinkService = (service) => {
  return request.put(`user/link?service=${service}`, { config: { isShowMessage: false } })
}

export const resetPwdWithoutToken = (oldPwd, newPwd) => {
  return request.put(
    'user/password/reset/withoutPasswordResetToken',
    {
      newPassword: md5(newPwd),
      oldPassword: md5(oldPwd)
    },
    { config: { isShowMessage: false } }
  )
}

export const getEmailByLoginId = (loginId, type) => {
  return request.post(
    '/user/loginId/find/email',
    {
      loginId: loginId,
      type: type
    },
    { config: { isShowMessage: false } }
  )
}

export const setNotice = ({
  type,
  version = null
}) => {
  return request.post(
    '/user/notice',
    {
      type: type,
      version: version
    },
    { config: { isShowMessage: false } }
  )
}
