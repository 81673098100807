import userStore from '@/store/modules/user'
import appStore from '@/store/modules/app'
import funcKeyMap, { RoleType } from '@/config/permissionConfig'

const updatePermission = (el, binding) => {
  const permissionList = []

  if (userStore.state.userInfo) {
    const organization = userStore.state.userInfo.organization !== undefined ? userStore.state.userInfo.organization : ''
    if (organization.roleId === '1000') {
      permissionList.push('admin')
    } else if (organization.roleId === '1001') {
      permissionList.push('subAdmin')
    } else if (organization.roleId === '1002') {
      permissionList.push('member')
      if (organization.permsScope === 1 && organization.permsGroupId === '1000') permissionList.push('organizationUser')
      if (organization.permsScope === 2 && organization.permsGroupId === '1000' && organization.teamId === appStore.state.selectedTeam.teamId) permissionList.push('teamUser')
      if (organization.permsScope === 2 && organization.permsGroupId === '1001' && organization.teamId === appStore.state.selectedTeam.teamId) permissionList.push('curMember')
    } else {
      permissionList.push('normalUser')
    }
  } else {
    permissionList.push('normalUser')
  }

  var shouldDelete = false
  const values = binding.value
  if (Array.isArray(values)) {
    const has = values.findIndex(item => {
      return _isMatchPermisson(permissionList, item)
    })
    shouldDelete = has === -1
  } else if (typeof values === 'string') {
    if (values.trim() !== '') shouldDelete = !_isMatchPermisson(permissionList, values)
  } else {
    shouldDelete = true
  }
  if (shouldDelete && el.parentNode) {
    el.parentNode.removeChild(el)
    // el.style.display = 'none'
  }
}

const _isMatchPermisson = (permissionList, item) => {
  const type = typeof item
  if (type === 'string') {
    return permissionList.includes(item)
  } else if (type === 'object') {
    if (permissionList.includes(item.role)) {
      return item.condition()
    }
  } else {
    return false
  }
}

export const funcIsEnable = (value) => {
  var shouldShow = false
  try {
    var funcKey = ''
    var params = {}
    if (typeof value === 'string') {
      funcKey = value
    } else {
      funcKey = value.name
      delete value.name
      params = Object.assign(params, value)
    }

    var userType = 'normal'
    if (userStore.state.userInfo && userStore.state.userInfo.organization) {
      const organization = userStore.state.userInfo.organization
      if (organization.roleId === RoleType.MainAdmin) {
        userType = 'main-admin'
      } else if (organization.roleId === RoleType.CoAdmin) {
        userType = 'co-admin'
      } else if (organization.roleId === RoleType.SubAdmin) {
        userType = 'sub-admin'
      } else if (organization.roleId === RoleType.Normal) {
        userType = organization.permsGroupId === '1000' ? 'role-member-rw' : 'role-member-r'
      }
    }

    const rules = funcKeyMap[funcKey]
    const rule = rules[userType]

    if (rule === undefined) {
      shouldShow = true
    } else if (typeof rule === 'boolean') {
      shouldShow = rule
    } else {
      if (rules.params.includes('user')) {
        params.user = userStore.state.userInfo
      }
      if (rules.params.includes('currentTeam')) {
        params.currentTeam = appStore.state.selectedTeam
      }
      shouldShow = rule(params)
    }
  } catch (_error) {
    shouldShow = false
  }
  return shouldShow
}

const updateFuncKey = (el, binding) => {
  if (!funcIsEnable(binding.value) && el.parentNode) {
    el.parentNode.removeChild(el)
  }
}

const setPermissions = (app) => {
  app.directive('hasPermission', {
    mounted (el, binding) {
      updatePermission(el, binding)
    },
    updated (el, binding) {
      updatePermission(el, binding)
    }

  })

  app.directive('funcKey', {
    mounted (el, binding) {
      updateFuncKey(el, binding)
    },
    updated (el, binding) {
      updateFuncKey(el, binding)
    }
  })
}

export default setPermissions
