<template>
  <a-modal
    :closable="false"
    :footer="false"
    :keyboard="false"
    :maskClosable="false"
    :visible="visible"
    :zIndex="1000"
    centered
    @cancel="visible = false"
  >
    <div class="page">
      <h3 style="background: #2b3549; margin:-2.5rem -2.5rem 2rem -2.5rem; padding:1.2rem; color:#fff">[공지]업데이트 안내</h3>
      <p>안녕하세요! 유캔싸인 운영팀입니다.</p>
      <p>요청자 기기 하나로 모든 참여자 서명을 진행할 수 있는,</p>
      <p>대면서명 기능이 추가되었습니다.</p>
      <br/>
      <p>오프라인에서도 쉽고, 간편하게 전자계약을 진행해 보세요.</p>
      <p>자세한 내용은 아래 링크를 클릭해 확인해 보세요 :)</p>
      <br/>
      <p><a href="https://ucansign.oopy.io/0d065d25-d254-4c2f-b510-c80da02c37ba" target="_blank">👉업데이트 내용 자세히 알아보기</a></p>
      <br/>
      <p>앞으로도 더 나은 서비스를 제공해 드리기 위해 노력하는 유캔싸인이 되겠습니다.</p>
      <p>감사합니다 :)</p>
    </div>
    <div class="close-contents">
      <a-checkbox v-model:checked="dontShowAgain">다시 보지않기</a-checkbox>
      <a-button class="close-button" style="background: #aaa; border-color:#aaa" type="primary" @click="hide">닫기</a-button>
    </div>
  </a-modal>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { setNotice as submit } from 'api/user'
import { useStore } from 'vuex'
import { Modal } from 'ant-design-vue'
import { NOTI1_VERSION } from '@/config/config'

export default {
  setup (props, { emit }) {
    const store = useStore()
    const state = reactive({
      visible: false,
      dontShowAgain: false
    })

    const show = () => {
      state.visible = true
    }

    const hide = () => {
      if (state.dontShowAgain) {
        submit({
          type: 'notice1',
          version: parseFloat(NOTI1_VERSION)
        })
          .then(() => {
            store.dispatch('userInfo').then(() => {
              state.visible = false
              emit('confirm')
            })
          })
          .catch((err) => {
            Modal.error({ content: err.msg })
          })
      } else {
        state.visible = false
        emit('confirm')
      }
    }

    return {
      ...toRefs(state),
      show,
      hide
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .btn {
    height: 42px;
    line-height: 42px;
    padding: 0;
    margin: 50px auto;
    width: 200px;
  }

  img {
    object-fit: cover;
    padding-bottom: 3rem;
    width: 80%;
    max-width: 340px;
    margin: 0 auto;
  }

  h3 {
    text-align: center;
    font-size: 20px;
    margin: 15px 0px 20px;
    line-height: 28px;
    font-weight: 500;

  }

  h4 {
    text-align: center;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    color: #555;

    strong {
      font-weight: 500;
      color: #000
    }
  }
}

.close-contents {
  text-align: right;
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;

  .close-button {
    width: 60px;
    height: 32px;
  }
}
</style>
