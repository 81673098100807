<template>
  <div class="tab-content">
    <div v-show="!currentFile">
      <a-upload-dragger
        :showUploadList="false"
        class="upload-wrap"
        :beforeUpload="beforeUpload"
        :transformFile="transformFile"
        :action="uploadApi"
        accept=".hwp, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .pdf, .jpg, .jpge, .png, .ppts, .docs"
      >
        <i class="ico ico-file-upload"></i>
        <!-- <strong>Upload your files</strong> -->
        <strong></strong>
        <small class="text-gray upload-tip">업로드할 파일을 이곳에 끌어다 놓으세요.</small>
        <div class="file-box">
          <a-button class="btn" type="primary">
            파일 찾기 <i class="arrow arrow-md right arrow-white"></i></a-button>
        </div>
      </a-upload-dragger>
      <!-- //파일 업로드 영역 -->
      <div class="text-center mt15">
        pdf 파일로 변환 후 업로드 하는 것을 권장합니다.
        <p class="form-guide">
          - 지원파일 : hwp, doc, docs, xls, xlsx, ppt, ppts, pdf, jpg, jpge, png 등<br/>
          - 업로드 가능 용량 : 최대 5MB
        </p>
      </div>
      <div class="btn-center">
        <a-button class="btn" @click="handleUpload" style="width: 240px;" :disabled="true">시작하기</a-button>
      </div>
    </div>
    <div class="" v-if="currentFile">
      <div class="upload-wrap after">
        <i class="ico ico-file-complete"></i>
        <!-- <strong>Upload complete</strong> -->
        <strong></strong>
        <small class="text-gray upload-tip">문서 선택이 완료되었습니다.</small>
        <div class="file-box">
          <!-- <input
            type="file"
            class="input-file"
          /> -->
          <div class="input-group form-control form-lg">
            <input
              type="text"
              class="form-upload ellipsis file-name"
              placeholder="선택된 파일 없음"
              :value="currentFile.name"
              readonly="true"
            />
            <b role="button" class="input-group-addon text-gray"
               @click="cancelFile"
            >취소</b>
          </div>
        </div>
      </div>
      <!-- //파일 업로드 영역 -->
      <div class="btn-center">
        <a-button class="btn" @click="handleUpload" style="width:240px;" type="primary">시작하기</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, toRefs } from 'vue'
import { file2Base64 } from 'utils/helper'
import { useStore } from 'vuex'
// import { useRouter } from 'vue-router'
import { uploadFile } from 'api/sign'
import { Modal } from 'ant-design-vue'

const uploadApi = process.env.VUE_APP_API_URL + '/documents/upload'
export default defineComponent({
  setup (props, { emit }) {
    const state = reactive({
      currentFile: null,
      uploadApi
    })

    const beforeUpload = file => {
      const limitSize = 5 * 1024 * 1024
      if (file.size > limitSize) {
        Modal.error({
          content: '파일 업로드 최대 용량은 5MB 입니다.'
        })
        return false
      } else {
        state.currentFile = file
        return false
      }
    }

    const transformFile = file => {
      return new Promise((resolve, reject) => {
        file2Base64(file).then(res => {
          resolve(res.split(',')[1])
        })
      })
    }
    const store = useStore()
    // const router = useRouter()
    const uploadErrModal = ref(null)
    const handleUpload = async () => {
      emit('startUpload')
      const formatFile = await file2Base64(state.currentFile)
      const nameArray = state.currentFile.name.split('.')
      uploadFile({
        base64: formatFile.split(',')[1],
        ext: '.' + nameArray[nameArray.length - 1]
      }).then(res => {
        if (res) {
          const currentPdf = {
            name: state.currentFile.name,
            pdfUrl: res.result.url,
            fileId: res.result.fileId
          }
          store.dispatch('setCurrentPdf', currentPdf)
          emit('finishUpload', currentPdf, '내컴퓨터')
        }
      }).catch(error => {
        Modal.error({
          content: error.msg
        })
      }).finally(() => {
        emit('endUpload')
      })
    }

    const cancelFile = () => {
      state.currentFile = null
    }

    return {
      beforeUpload,
      transformFile,
      handleUpload,
      cancelFile,
      uploadErrModal,
      ...toRefs(state)
    }
  }
})
</script>

<style lang="less" scoped>
.file-name {
  border: none;

  &:focus {
    border: none;
    outline: none;
  }
}

.upload-tip {
  font-size: 16px;
}
</style>
