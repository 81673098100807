<template>
  <div class="tab-content">
    <a-spin :spinning="loading" style="height:240px">
      <ul class="chk-list">
        <li v-for="(template, index) in myTemplates" :key="index">
          <label :for="`myTemplate_${index}`" class="input-chk">
            <input type="radio" name="myTemplate" :id="`myTemplate_${index}`" :value="index"
                   @change="handleChange(template)"/>
            <div class="chk-box">
              <span class="checkbox"><i class="ico ico-docu"></i></span>
              <span class="txt">{{ template.name }}</span>
            </div>
          </label>
        </li>
        <div v-if="myTemplates.length < 1">
          <div class="empty-msg">
            <div v-if="!isMobile">
              <svg class="ant-empty-img-simple" width="64" height="41" viewBox="0 0 64 41">
                <g transform="translate(0 1)" fill="none" fill-rule="evenodd">
                  <ellipse class="ant-empty-img-simple-ellipse" fill="#F5F5F5" cx="32" cy="33" rx="32" ry="7"></ellipse>
                  <g class="ant-empty-img-simple-g" fill-rule="nonzero" stroke="#D9D9D9">
                    <path
                      d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                    <path
                      d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                      fill="#FAFAFA" class="ant-empty-img-simple-path"></path>
                  </g>
                </g>
              </svg>
              <p>템플릿 문서가 없습니다.</p>
            </div>
            <div v-else>
              <svg class="ant-empty-img-simple" width="64" height="41" viewBox="0 0 64 41">
                <g transform="translate(0 1)" fill="none" fill-rule="evenodd">
                  <ellipse class="ant-empty-img-simple-ellipse" fill="#F5F5F5" cx="32" cy="33" rx="32" ry="7"></ellipse>
                  <g class="ant-empty-img-simple-g" fill-rule="nonzero" stroke="#D9D9D9">
                    <path
                      d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                    <path
                      d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                      fill="#FAFAFA" class="ant-empty-img-simple-path"></path>
                  </g>
                </g>
              </svg>
              <p>PC에서 나만의 템플릿을 만드세요.</p>
            </div>
          </div>
        </div>
      </ul>
    </a-spin>
    <div class="btn-center" v-show="myTemplates.length == 0">
      <a-button type="primary" style="width: 240px;" class="btn" v-show="!isMobile" @click="toTemplate">템플릿 만들기</a-button>
    </div>
    <div class="btn-center" v-show="myTemplates.length">
      <a-button :disabled="!currentTemplate" type="primary" style="width: 240px;" class="btn" @click="goNext">업로드
      </a-button>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { useMyTemplate } from 'use/sign/useSamples'
import { useMediaQuery } from '@vueuse/core'

export default {
  setup (props, { emit }) {
    const isMobile = useMediaQuery('(max-width: 768px)').value
    const state = reactive({
      currentTemplate: null
    })
    const {
      loading,
      myTemplates
    } = useMyTemplate()
    const handleChange = (template) => {
      state.currentTemplate = template
    }
    const goNext = () => {
      if (state.currentTemplate != null) {
        const currentPdf = {
          name: state.currentTemplate.name,
          documentId: state.currentTemplate.documentId
        }
        emit('finishUpload', currentPdf, '샘플')
      }
    }
    const toTemplate = () => {
      emit('toTemplate')
    }

    return {
      isMobile,
      loading,
      myTemplates,
      handleChange,
      goNext,
      toTemplate,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.chk-list {
  overflow: auto;
  max-height: 240px;
}

@media only screen and (max-width: 768px) {
  .btn-center {
    margin-top: 15px;
  }
}

.empty-msg {
  width: 100%;
  min-height: 200px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  p {
    color: #aaa;
    margin-top: 1rem
  }
}
</style>
