import { Message, Modal } from 'ant-design-vue'
import axios from 'axios'
import store from '@/store'
import { clearUserInfo, signFromLink } from 'utils/helper'
import router from '@/router'
import { refreshToken as refresh } from '@/api/user'
import { URLSearchParams } from 'core-js/modules/web.url-search-params.constructor'

const timeout = 60000
let subscribers = []
let isTokenRefreshing = false

const subscribeTokenRefresh = callback => {
  console.log('📥Save Request📥')
  subscribers.push(callback)
}

const onRefreshed = token => {
  console.log('🍔Activate Request🍔')
  subscribers.forEach((callback) => {
    callback(token)
  })
  subscribers = []
}

const refreshingToken = (response) => {
  isTokenRefreshing = true
  console.log('🔁REFRSEH ON🔁', response)
  const oldAccessToken = store.state.user.token
  const oldRefreshToken = store.state.user.refresh

  // Reissue access Token
  refresh(oldAccessToken, oldRefreshToken).then(res => {
    const newAccessToken = res.result.accessToken
    const newRefreshToken = res.result.refreshToken
    store.commit('setToken', newAccessToken)
    if (newRefreshToken) store.commit('setRefreshToken', newRefreshToken)
    onRefreshed(newAccessToken)
    return newAccessToken
  }).catch(err => {
    console.log(err)
  }).finally(() => {
    isTokenRefreshing = false
    console.log('refresh off')
  })
}

const handleResponseError = error => {
  if (error.response.status === 500) {
    router.push({ name: 'error' })
  } else if (error.response.status === 502) {
    router.push({ name: 'error' })
  } else {
    Modal.error({ content: 'Internet Error!' })
  }
  return Promise.reject(error)
}

const serializeParams = params => {
  const searchParams = new URLSearchParams()
  for (const key in params) {
    if (Array.isArray(params[key])) {
      params[key].forEach(value => searchParams.append(key, value))
    } else {
      searchParams.append(key, params[key])
    }
  }
  return searchParams.toString()
}

const createRequest = type => {
  const typeConf = {
    v1: process.env.VUE_APP_API_URL,
    openapi: process.env.VUE_APP_API_URL_OPENAPI
  }
  const request = axios.create({
    baseURL: typeConf[type],
    timeout
  })

  request.interceptors.request.use(config => {
    if (config.params) {
      config.paramsSerializer = params => serializeParams(params)
    }
    config.params = {
      ...config.params,
      timestamp: new Date().getTime()
    }
    const userToken = store.state.user.token
    if (userToken || !config.headers['x-ucansign-token']) config.headers['x-ucansign-token'] = userToken
    config.headers['accept-language'] = 'ko'

    const [isSignFromLink, linkKey, contactInfo, signMethodType] = signFromLink()
    if (isSignFromLink) {
      config.headers['x-contact-info'] = contactInfo
      config.headers['x-link-key'] = linkKey
      config.headers['x-signing-method-type'] = signMethodType
      config.headers['x-ucansign-token'] = 'none'
    }

    return config
  })

  request.interceptors.response.use(async response => {
    let {
      isShowMessage,
      successMessage,
      errorMessage,
      ignoreInterceptorsRes
    } = response.config.config || { isShowMessage: false }

    ignoreInterceptorsRes = typeof ignoreInterceptorsRes === 'undefined' ? false : ignoreInterceptorsRes
    if (ignoreInterceptorsRes === true) {
      if (response.status === 200) {
        return response
      } else {
        throw response.statusText
      }
    }

    isShowMessage = typeof isShowMessage === 'undefined' ? true : isShowMessage
    const {
      code,
      msg
    } = response.data
    const message = msg || 'Server Error!'

    // if access token is expired
    if (code === 441) {
      if (store.state.user.refresh) {
        const originalRequest = response.config

        if (isTokenRefreshing === true) {
          // if token is already refreshing, subscribing tokens
          return new Promise((resolve) => {
            subscribeTokenRefresh((token) => {
              originalRequest.headers['x-ucansign-token'] = token
              resolve(request(originalRequest))
            })
          })
        } else {
          // if token is not refreshing, activate Token Refeeshing
          const newAccessToken = refreshingToken(response)
          originalRequest.headers['x-ucansign-token'] = newAccessToken
          return await request(originalRequest)
        }
      } else {
        store.dispatch('logout')
        clearUserInfo()
        router.push({ name: 'Login' })
      }
    } else {
      if (code === 0) {
        isShowMessage && Message.success(successMessage || message)
      } else {
        if (code === 401) {
          store.dispatch('logout')
          clearUserInfo()
          router.push({ name: 'Login' })
        }
        isShowMessage && Modal.error({ content: errorMessage || message })
        throw response.data
      }
      return response.data
    }
  }, error => {
    handleResponseError(error)
  })
  return request
}

export default createRequest
