<template>
  <SimpleModal ref="modal" @confirm="$emit('confirm')" class="sign-start-modal">
    <template #title>
      <div class="cont-header">
        <h3>서명 요청 오류</h3>
      </div>
    </template>
    <template #content>
      <p>
        잔여포인트가 부족하여<br/>
        서명 요청을 진행할 수 없습니다.<br/>
        포인트 충전 후 다시 시도해 주세요.
      </p>
    </template>
    <template #footer>
      <a-button class="btn btn-gray-line" @click="onCancel" v-if="isChargeUser">취소</a-button>
      <a-button class="btn btn-blue" @click="onConfirm">{{ isChargeUser ? '포인트 충전하기' : '확인' }}</a-button>
    </template>
  </SimpleModal>
</template>

<script>
import { computed, reactive, ref, toRefs } from 'vue'
import SimpleModal from 'components/common/SimpleModal'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  components: { SimpleModal },
  setup () {
    const modal = ref(null)
    const store = useStore()
    const router = useRouter()

    const isChargeUser = computed(() => {
      const userInfo = store.state.user.userInfo
      if (!userInfo.organization) {
        return true
      } else {
        return userInfo.organization.roleId === '1000'
      }
    })
    const state = reactive({})

    const onConfirm = () => {
      if (isChargeUser.value) {
        router.push({
          name: 'Point',
          hash: '#1'
        })
      }
      modal.value.hide()
    }

    const onCancel = () => {
      modal.value.hide()
    }

    const show = () => {
      modal.value.show()
    }

    return {
      modal,
      isChargeUser,
      onCancel,
      onConfirm,
      show,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>

</style>
