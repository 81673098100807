import { useMediaQuery } from '@vueuse/core'

const handler = (el, binding) => {
  const isMobile = useMediaQuery('(max-width: 768px)')
  const isShow = isMobile.value ? binding.value : true
  el.style.display = isShow ? '' : 'none'
}

const setDevices = (app) => {
  app.directive('isMobileShow', {
    updated (el, binding) {
      handler(el, binding)
    },
    mounted (el, binding) {
      window.addEventListener('resize', () => {
        handler(el, binding)
      })
    },
    unmounted (el, binding) {
      window.removeEventListener('resize', handler(el, binding))
    }
  })
}

export default setDevices
