import request from 'utils/request'

/**
 * create new folder (创建新的文件夹)
 * @param {name: "test3", order: 1} postData obj
 * @returns
 */
export const createNewFolder = (postData) => {
  return request.post('/folders', postData)
}

/**
 * get folder list
 * @param {*} params
 * @returns
 */
export const getFolderList = (params) => {
  return request({
    url: '/folders',
    method: 'get',
    params
  })
}
/**
 * delete folder (删除文件夹)
 * @param {string} params doc_id
 * @returns
 */
export const delFolder = (params) => {
  return request({
    url: `/folders/${params}`,
    method: 'delete'
  })
}

/**
 *  rename folder (重新命名文件)
 * @param {Object} params
 * @returns
 */
export const renameFolder = (params) => {
  return request.put(`/folders/${params.folderId}/name`, {
    name: params.name
  })
}

/**
 * order folder (移动文件夹的数据)
 * @param {Object} params doc_id order
 * @returns
 */
export const orderFolder = (params) => {
  return request.put(`/folders/${params.folderId}/order`, params)
}
/**
 * get documents list (获取文档列表)
 * @param {Object} params
 * @returns
 */
export const getDocList = (params) => {
  return request.get('/documents?', {
    params: params
  })
}

/**
 * move docuemnts to folder (移动文件夹)
 * @param {Object} params
 * @returns
 */
export const moveDocument = (folderId, params) => {
  return request.put(`/folders/${folderId}/documents`, params)
}

/**
 *
 * @param {Object} params documentId
 * @returns
 */
export const delDocument = (params) => {
  return request.put('/documents/archive', params)
}

export const clearanceDocument = (params) => {
  return request.post('/folders/clearance', { managedDocuments: params })
}

/**
 * get doc history (获取文档的历史记录)
 * @param {string} docId documentId
 * @returns
 */
export const getDocHistory = (docId) => {
  return request.get(`/documents/${docId}/histories`)
}

/**
 * get auths (获取文档的认证列表)
 * @param {string} docId
 * @returns
 */
export const getDocAuths = (docId) => {
  return request.get(`/documents/${docId}/participant-auths`)
}

/**
 *  get annex (获取文档的附件列表)
 * @param {string} docId
 * @returns
 */
export const getDocAnnex = (docId) => {
  return request.get(`/documents/${docId}/attachments`)
}

/**
 * resend email notice (重新发送邮件通知)
 * @param {string} docId
 * @returns
 */
export const resendEmail = (docId) => {
  return request.post(`/documents/${docId}/request/reminder`, '', { config: { isShowMessage: false } })
}

export const setExpiry = (docId, params) => {
  return request.put(`/documents/${docId}/expiry`, params, { config: { isShowMessage: false } })
}

/**
 * cancel (取消签名)
 * @param {*} params
 * @returns
 */
export const cancelLation = (params) => {
  return request.post(`/documents/${params.documentId}/request/cancellation`, {
    message: params.message,
    disableAccess: params.disableAccess
  }, { config: { isShowMessage: false } })
}

/**
 * reject (拒绝签名)
 * @param {*} params
 * @returns
 */
export const reject = (params) => {
  return request.post(`/documents/${params.documentId}/participants/${params.participantId}/rejection`, { message: params.message }, { config: { isShowMessage: false } })
}

export const getDetail = (docId) => {
  return request.get(`/documents/${docId}`, { signingExpiryPeriod: 20160 }, { config: { isShowMessage: false } })
}

export const completelyDelete = id => {
  return request.delete(`documents/${id}/archive`)
}

export const decryptLink = (encryptedLink) => {
  return request.get(`/documents/link?encryptedLink=${encryptedLink}`)
}

export const checkDocument = (params) => {
  if (Array.isArray(params)) {
    return request.put('/documents/check', params)
  } else {
    return request.put(`/documents/${params}/check`)
  }
}

export const checkAllDocuments = () => {
  return request.put('/documents/check/all')
}

export const requestDocumentFiles = (params) => {
  return request.put('/documents/files', params)
}

export const requestAttachmentFiles = (documentId) => {
  return request.get(`/documents/${documentId}/files/attachments`)
}

export const modifyReservedDocumentReservationDate = (documentId, date) => {
  return request.put(`documents/${documentId}/reservation`, { reservationDate: date })
}

export const cancelReservedDocument = (documentId) => {
  return request.put(`documents/${documentId}/reservation/cancel`)
}

export const immediateTransmit = (documentId) => {
  return request.put(`documents/${documentId}/reservation/transmit`)
}

export const saveStartFaceToFaceHistory = (documentId) => {
  return request.put(`documents/${documentId}/histories/start`)
}
