import router from '@/router'
import { i18nt } from '@/core/i18n'
import { Modal } from 'ant-design-vue'

/**
 * jump to url
 * @param {* router-path} url
 */
export const jumpToUrl = (url) => {
  router.push(`${url}`)
}

export const jumpToHome = () => {
  router.push('/')
}

export const jumpToLogin = () => {
  Modal.error({ content: i18nt('common.error.login') })
  router.push({ name: 'Login' })
}

export const jumpToSettings = () => {
  router.push({ name: 'Settings' })
}
