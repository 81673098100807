<template>
  <a-modal
    :closable="false"
    :footer="null"
    :maskClosable="false"
    :visible="visible"
    centered
    width="720px"
    @cancel="cancel"
  >
    <div class="face-to-face-modal">
      <div class="modal-header">
        요청자 대면서명 안내사항
      </div>
      <div class="modal-wrapper">
        <div class="cnt">
          <table>
            <tr>
              <td>문서명</td>
              <td>{{ document.name }}</td>
            </tr>
            <tr>
              <td>서명 요청자 정보</td>
              <td>{{ document.requester.name }}<br v-if="isMobile"/>({{ document.requester.signingContactInfo }})</td>
            </tr>
            <tr>
              <td>서명 참여자 정보</td>
              <td>
                <div v-for="participant in document.participants" :key="participant.participantId">
                  <span class="order">{{ participant.signingOrder }}. </span>{{ participant.name }}<br v-if="isMobile"/>({{ participant.signingContactInfo }})
                </div>
              </td>
            </tr>
          </table>
          <div class="gray-box">
            <ol>
              <li><span>- 참여자 서명을 시작하기 전, 해당 참여자가 맞는지 신원 확인을 꼭 해주세요.</span></li>
              <li><span>- 실제 참여자가 아닌, 제 3자가 서명에 참여하여 발생되는 계약 상 문제는 유캔싸인이 책임지지 않습니다.</span></li>
            </ol>
          </div>
          <p class="approve">모든 내용 확인 후 첫 번째 서명 참여자에게 기기를 전달해 주세요.</p>
        </div>
      </div>
      <div class="modal-footer">
        <a-button class="btn btn-cancel" type="default" @click="cancel">
          <span>나가기</span>
        </a-button>
        <a-button class="btn btn-confirm" type="primary" @click="handleOk">
          <span>대면서명 시작하기</span>
        </a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { useMediaQuery } from '@vueuse/core'
import { saveStartFaceToFaceHistory } from 'api/documents'

export default {
  setup (props, { emit }) {
    const isMobile = useMediaQuery('(max-width: 768px)')
    const state = reactive({
      document: null,
      visible: false
    })

    const show = (document) => {
      state.document = document
      state.visible = true
    }

    const cancel = () => {
      state.visible = false
    }

    const handleOk = async () => {
      await saveStartFaceToFaceHistory(state.document.documentId)
      emit('confirm', state.document)
    }

    return {
      isMobile,
      show,
      cancel,
      handleOk,
      ...toRefs(state)
    }
  }

}
</script>
<style lang="less" scoped>
.face-to-face-modal {
  text-align: center;
}

.modal-header {
  display: inherit;
  font-size: 18px;
  font-weight: 700;
}

.modal-wrapper {
  table {
    width: 100%;

    td {
      padding: 15px;
      border: 1px solid #f0f0f0;
      text-align: left;
      vertical-align: middle;

      &:nth-child(1) {
        background-color: #fafafa;
      }
    }
  }

  .gray-box {
    margin: 2rem;
    padding: 1.5rem;
    border: 1px solid #eee;
    background: #f7f7f7;
    border-radius: 5px;
    text-align: left;
  }

  .approve {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 24px;
  }
}

.modal-footer {
  width: 100%;
  justify-content: center;
  display: flex;
  border-top: 0;
  padding: 0;

  .btn-cancel {
    margin-right: 5px;
    width: 10.375em;
    min-width: 160px;
  }

  .btn-confirm {
    margin-left: 5px;
    width: 10.375em;
    min-width: 160px;
  }
}
</style>
