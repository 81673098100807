import dayjs from 'dayjs'

export const getQueryParameters = (options) => {
  const url = options.url
  const search = url.split('?')[1]
  if (!search) {
    return {}
  }
  return JSON.parse('{"' + decodeURIComponent(search)
    .replace(/"/g, '\\"')
    .replace(/&/g, '","')
    .replace(/=/g, '":"') + '"}')
}

export const getBody = (options) => {
  return options.body && JSON.parse(options.body)
}

export const inArray = (search, array) => {
  for (let index = 0; index < array.length; index++) {
    if (array[index] === search) {
      return true
    }
  }
  return false
}

export const sleep = (numberMillis) => {
  var now = new Date()
  var exitTime = now.getTime() + numberMillis
  while (true) {
    now = new Date()
    if (now.getTime() > exitTime) {
      return
    }
  }
}

export const FormatTime = (text, format = 'YYYY-MM-DD HH:mm:ss') => {
  if (text === 'now' || text === null) return dayjs().format(format)
  return dayjs(text).format(format)
}

export const contentPre = error => {
  var value = ''
  if (error instanceof Object) {
    value = error.msg || error.message
  } else {
    value = error
  }
  return (<pre style="white-space: pre-wrap; word-wrap: break-word;">{value}</pre>)
}

export const isValidChangeReservation = (resDate) => {
  if (resDate === null || typeof resDate === 'undefined' || !dayjs(resDate).isValid()) return true

  if (typeof resDate === 'string') {
    return dayjs().add(5, 'minute').isBefore(dayjs(resDate))
  } else {
    return dayjs().add(5, 'minute').isBefore(resDate)
  }
}
