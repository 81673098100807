import store from 'store'
import { locale } from 'config/config'
import { getPointBalance } from 'api/point'

const app = {
  state: {
    // locale: store.get(locale) || 'ko-KR',
    locale: 'ko-KR',
    device: store.get('device'),
    collapsed: store.get('collapsed') || false,
    currentMenu: null,
    point: 0,
    selectedTeam: store.get('selectedTeam') || null,
    selectedFolder: null,
    teams: [],
    immediateSignPop: false,
    immediateSignDocumentId: null,
    contractDetail: null,
    firstRequestPaymentPop: false,
    selectedTeamList: []
  },
  mutations: {
    setLocale (state, lang) {
      store.set(locale, lang)
      state.locale = lang
    },
    setDevice (state, val) {
      store.set('device', val)
      state.device = val
    },
    setCollapsed (state, val) {
      store.set('collapsed', val)
      state.collapsed = val
    },
    SET_CURRENT_MENU (state, menuOption) {
      state.currentMenu = menuOption
    },
    SET_POINT (state, point) {
      state.point = point
    },
    SET_TEAM (state, selectedTeam) {
      state.selectedTeam = selectedTeam
    },
    SET_FOLDER (state, folder) {
      state.selectedFolder = folder
    },
    SET_TEAMS (state, teams) {
      state.teams = teams
    },
    SET_CONTRACT_DETAIL (state, contractDetail) {
      state.contractDetail = contractDetail
    },
    SET_SELECTED_TEAM_LIST (state, selectedTeamList) {
      state.selectedTeamList = selectedTeamList
    }
  },
  actions: {
    setDevice ({ commit }, device) {
      commit('setDevice', device)
    },
    setLocale ({ commit }, lang) {
      commit('setLocale', lang)
    },
    setCollapsed ({ commit }, collapsed) {
      commit('setCollapsed', collapsed)
    },
    setCurrentMenu ({ commit }, menu) {
      commit('SET_CURRENT_MENU', menu)
    },
    async setPoint ({ commit }) {
      const res = await getPointBalance()
      commit('SET_POINT', res.result.balance)
    },
    setTeam ({ commit }, selectedTeam) {
      commit('SET_TEAM', selectedTeam)
      store.set('selectedTeam', selectedTeam)
    },
    setFolder ({ commit }, folder) {
      commit('SET_FOLDER', folder)
    },
    setTeams ({ commit }, teams) {
      commit('SET_TEAMS', teams)
    },
    setContractDetail ({ commit }, contractDetail) {
      commit('SET_CONTRACT_DETAIL', contractDetail)
    },
    setSelectedTeamList ({ commit }, selectedTeamList) {
      commit('SET_SELECTED_TEAM_LIST', selectedTeamList)
    }
  },
  getters: {
    locale: state => {
      return state.locale
    },
    device: state => {
      return state.device
    }
  }
}

export default app
