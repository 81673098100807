<template>
  <div ref="banner" class="banner-section">
    <ul class="top-banner slick-initialized slick-slider">
      <div class="slick-list draggable">
        <div class="slick-track" style="opacity: 1; width: 100%;">
          <li class="slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false" style="width:100%; " tabindex="0">
            <a href="https://ucansign.oopy.io/7efe3a29-f061-45df-aa7d-5d089ea90197" rel="noopener noreferrer" target="_blank" title="새창열림" tabindex="0">
              <img src="@/assets/images/top_ban.gif">
            </a>
          </li>
        </div>
        <button type="button" @click="todayCloseWinBanner()" class="close" style="display: flex;">
          <span>오늘 하루 닫기</span>
          <i class="ico-x"></i>
        </button>
      </div>
    </ul>
  </div>
</template>

<script>
import { ref } from 'vue'
import { Modal } from 'ant-design-vue'
import { useStore } from 'vuex'
import { setNotice as submit } from 'api/user'

export default {
  setup () {
    const store = useStore()
    const banner = ref(null)
    const showBanner = () => {
      const noti = store.state.user.userInfo.notifications
      const date = new Date(noti.banner).getTime()
      const curDate = new Date().getTime()
      if (curDate > date) {
        banner.value.hidden = false
      } else {
        banner.value.hidden = true
      }
    }
    const todayCloseWinBanner = () => {
      submit({ type: 'banner' })
        .then(() => {
          banner.value.hidden = true
        })
        .catch((err) => {
          Modal.error({ content: err.msg })
        })
    }
    return {
      banner,
      showBanner,
      todayCloseWinBanner
    }
  }
}
</script>

<style lang="less" scoped>

//Banner
@media only screen and (max-width: 768px) {
  .banner-section .banner-m {
    display: block;
    width: 100%
  }

  .banner-section .banner-m img {
    width: 100%
  }

  .banner-section .banner-pc {
    display: none
  }

  .banner-section img {
    left: auto;
    transform: translateX(0px)
  }

  section {
    padding: 8rem 0
  }
}

.banner-section .slick-slider {
  position: relative;
  display: block;
  height: 100%;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  background: #f7f7f7
}

.banner-section .slick-slider .slick-track {
  -moz-transform-origin: top left;
  -o-transform-origin: top left;
  -ms-transform-origin: top left;
  -webkit-transform-origin: top left;
  transform-origin: top left;
}

.banner-section .slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
  text-align: center;
}

.banner-section .slick-initialized .slick-slide {
  display: block;
  outline: 0;
}

.banner-section .slick-arrow {
  width: 3.6rem;
  height: 3.6rem;
  background: rgba(255, 255, 255, 0.5);
  z-index: 1;
  cursor: pointer;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

@media (hover: hover) {
  .banner-section .slick-arrow:hover {
    background: rgba(255, 255, 255, 0.8);
  }
}

.banner-section .slick-arrow.slick-prev:before {
  background-position: -200px -100px;
}

.banner-section .slick-arrow.slick-next:before {
  background-position: -250px -100px;
}

.banner-section .slick-prev,
.banner-section .slick-next {
  position: absolute;
  left: 50%;
  top: 50%;
  line-height: 0;
  font-size: 0;
  z-index: 1001;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.banner-section .slick-prev:before,
.banner-section .slick-next:before {
  content: '';
  width: 7px;
  height: 13px;
  opacity: 0.7;
  filter: alpha(opacity=70);
}

.banner-section .slick-prev {
  margin-left: -30rem;
}

.banner-section .slick-next {
  margin-left: 26.4rem;
}

/* ----------------------------------------------------------------
top banner
---------------------------------------------------------------- */
/*
.tbn-none .banner-section {
     display: none;
}
*/

.banner-section {
  position: relative
}

.banner-section .banner-m {
  display: none
}

.banner-section .banner-pc {
  max-width: none
}

@media only screen and (max-width: 1600px) {
  .banner-section .slick-slide {
    text-align: left;
    margin-left: 20px
  }
}

@media only screen and (max-width: 1500px) {
  .banner-section .slick-slide img {
    width: 85%
  }
}

@media only screen and (max-width: 1024px) {
  .banner-section img {
    position: relative
  }
}

@media only screen and (max-width: 768px) {
  .banner-section .slick-slide {
    position: relative;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    height: 36px;
  }

  .banner-section .slick-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover
  }

  .banner-section .banner-m {
    display: block;
    width: 100%
  }

  .banner-section .banner-m img {
    width: 100%
  }

  .banner-section .banner-pc {
    display: none
  }
}

@media only screen and (max-width: 640px) {
  .banner-section .banner-m img {
    width: auto
  }
}

.banner-section .item {
  height: 5em;
  padding: 0.95em 0;
  text-align: center;
  letter-spacing: -0.1rem;
  background: no-repeat center;
  background-size: cover;
}

.banner-section .item strong {
  font-size: 1.8rem;
  color: #333333;
}

@media only screen and (max-width: 640px) {
  .banner-section .item strong {
    font-size: 1.6rem;
  }
}

.banner-section .item strong i {
  margin: 0 0.8em;
}

@media only screen and (max-width: 640px) {
  .banner-section .item strong i {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.banner-section .item p {
  font-size: 1.3rem;
  line-height: 2.9rem;
}

.banner-section .ico-x {
  display: inline-block;
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  background: #aaa;
  margin-left: 1rem;
}

.banner-section .ico-x:before, .banner-section .ico-x:after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 1.5rem;
  height: 1px;
  background: #fff;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.banner-section .ico-x:before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.banner-section .ico-x:after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.banner-section .close {
  position: absolute;
  top: 5px;
  right: 15px;
  padding: 0.2em;
  font-size: 1.3rem;
  line-height: 1.4rem;
  opacity: 1;
  -webkit-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
  -ms-transition: 0.2s ease;
  -o-transition: 0.2s ease;
  transition: 0.2s ease;
}

@media (hover: hover) {
  .banner-section .close:hover {
    opacity: 1;
    filter: alpha(opacity=100);
  }
}

.banner-section .close span {
  display: inline-block;
  vertical-align: middle;
  color: #000 !important;
}

@media only screen and (max-width: 640px) {
  .banner-section .ico-x, .banner-section .ico-x {
    display: none
  }

}

@media only screen and (max-width: 640px) {
  .banner-section .close span {
    display: none;
  }

  .banner-section .close .ico-x:before, .banner-section .close .ico-x:after {
    width: 1.8rem;
  }
}
</style>
