export default {
  common: {
    set: '설정',
    complete: '완료',
    cancel: '취소',
    modify: '수정',
    change_s: '변경',
    delete: '삭제',
    add: '추가',
    confirm: '확인',
    move: '이동',
    change: '변경하기',
    refuse: '거절하기',
    logout: '로그아웃',
    langs: '언어 설정',
    copyright: 'Copyright © 2022 UDID Inc. All rights reserved.',
    error: {
      login: '로그인 정보가 만료되었습니다',
      empty: '작업 개체가 비어 있습니다.'
    },
    create: '생성하기',
    save: '저장하기',
    pay_request: '알림톡 결제 요청'
  },
  user: {
    login: {
      title: '무료 전자계약 솔루션, 유캔싸인',
      payapp_account: '페이앱',
      sub_account: '부계정',
      id_placeholder: '아이디를 입력하세요',
      // main_id_placeholder: '주계정 아이디를 입력하세요',
      sub_id_placeholder: '부계정 아이디를 입력하세요',
      pwd_placeholder: '비밀번호를 입력하세요',
      save_id: '아이디 저장',
      login: '로그인',
      join: '회원가입',
      find_id: '아이디 찾기',
      find_pwd: '비밀번호 찾기'
    },
    join: {
      title: '유캔싸인 회원가입',
      mobile_phone_number: '휴대전화번호',
      mobile_phone_number_placeholder: '전화번호를 입력해주세요',
      send_a_verification_number: '인증번호 발송',
      enter_verification_number: '인증번호 입력',
      enter_verification_number_placeholder: '인증번호를 입력해주세요',
      verificate: '인증하기',
      verification_number_tip: '* 통신 환경에 따라 메시지 수신이 지연될 수 있습니다.',
      verification_completed_tip: '휴대폰 인증이 완료되었습니다.',
      id: '아이디',
      id_placeholder: '아이디를 입력해주세요',
      validate_id: '아이디 중복확인',
      id_tip: '아이디는 4자리 이상의 영어 소문자, 숫자만 이용이 가능하며 첫 글자는 영문만 가능합니다.',
      password: '비밀번호',
      password_placeholder: '비밀번호를 입력해주세요',
      password_tip: '영문과 숫자를 섞어 8자리 이상 입력하세요. (특수문자 입력 가능)',
      verify_password: '비밀번호 확인',
      verify_password_placeholder: '비밀번호를 다시 한번 입력해주세요',
      email: '이메일',
      email_placeholder: '이메일을 입력해주세요',
      buisness: {
        title: '사업자',
        name: '대표자명',
        name_placeholder: '대표자명을 입력해주세요',
        business_name: '사업자명',
        business_name_placeholder: '사업자명을 입력해주세요',
        business_registration_number: '사업자등록번호',
        business_registration_number_placeholder: '사업자등록번호를 입력해주세요'
      },
      personal: {
        title: '개인',
        name: '이름',
        name_placeholder: '이름을 입력해주세요'
      },
      policy: {
        title: '위 내용에 모두 동의하고 유캔싸인에 접속합니다.',
        view: '보기',
        terms_and_conditions: '[필수] 유캔싸인 이용약관',
        privacy_policy: '[필수] 개인정보 처리방침',
        payapp_easy_subscription_terms: '[필수] 개인정보의 수집 이용 목적',
        terms_and_conditions_for_payapp_privacy: '[필수] 페이앱 개인정보 관련 약관',
        people_under_the_age_of_14_are_eligible_to_sign_up_and_use_the_service: '만 14세 미만은 서비스 이용이 불가합니다.'
      },
      cancel: '취소',
      sign_up: '가입하기',
      error: {
        mobile_phone_number_is_uncorrect: '휴대전화 번호가 입력되지 않았습니다.',
        please_input_sms_code: 'SMS로 전송된 인증번호를 입력해주세요.',
        id_should_start_with_letter: '아이디는 알파벳 소문자로 시작해야 합니다.',
        id_should_be_more_then_4_char: '아이디는 4글자 이상이어야 합니다.',
        please_agree_all_terms_and_conditions: '유캔싸인의 모든 이용 약관에 동의하시기 바랍니다.',
        please_check_data_with_red_border: '붉은 색으로 표시된 입력 정보를 다시 확인해주세요.'
      }
    },
    forget: {
      title: '무료 전자계약 솔루션, 유캔싸인',
      desc: '아이디를 잊으셨나요?&lt;br /&gt;가입 시 입력하신 &lt;em&gt;이름/이메일주소&lt;/em&gt;를 정확하게 입력하시면&lt;br /&gt;아이디를 확인하실 수 있습니다:)',
      id: {
        title: '아이디 찾기',
        name_placeholder: '회원 이름',
        find: '아이디 찾기'
      },
      password: {
        title: '비밀번호 찾기',
        id_placeholder: '회원 아이디',
        find: '비밀번호 찾기'
      },
      email_placeholder: '회원가입 시 기재한 이메일',
      login: '로그인'
    },
    forget_id_result: {
      title: '무료 전자계약 솔루션, 유캔싸인',
      tip: '회원님의 아이디는 &lt;em&gt;{id}&lt;/em&gt; 입니다.',
      find_password: '비밀번호 찾기',
      login: '로그인'
    },
    forget_password_result: {
      title: '무료 전자계약 솔루션, 유캔싸인',
      tip1: '입력하신 이메일 주소로&lt;br /&gt;&lt;em&gt;임시 비밀번호&lt;/em&gt;가 발급되었습니다.',
      tip2: '보안을 위해 로그인 후 비밀번호를 즉시 변경해 주세요',
      login: '로그인',
      do_not_receive_email: '이메일을 받지 못하셨다면? ',
      try_again: '다시 시도하기'
    },
    reset_password: {
      title: 'Reset password',
      old_password_placeholder: 'Pleae input old password',
      password_placeholder: '영문과 숫자를 섞어 8자리 이상 입력하세요. (특수문자 입력 가능)',
      confirm_password_placeholder: 'Please input new password again',
      confirm_password_error: 'Two passwords are not matched',
      submit: 'submit'
    }
  },
  documents: {
    sider: {
      sign: '서명 요청하기',
      document: '내 문서',
      demo: 'Demo'
    },
    header: {
      help: '도움말',
      document: '내 문서',
      template: '템플릿',
      bulkTransmit: '대량전송',
      docuEdit: '문서편집기',
      signStamp: '사인/도장',
      settings: '설정',
      point: '포인트 관리',
      pointList: '충전 및 사용내역',
      pointCharge: '충전하기',
      demo: 'Demo',
      orgIndex: '조직 전체 문서',
      orgOwnerIndex: '내 담당 문서',
      teamIndex: '팀 문서',
      teamOwnerIndex: '내 담당 문서',
      teamTemplate: '팀 템플릿'
    },
    content: {
      folderBtn: '폴더 목록',
      emptyFolderMsg: {
        msg1: '폴더가 없습니다.',
        msg2: '폴더를 추가하여 문서를',
        msg3: '체계적으로 관리해 보세요.'
      },
      newBtn: '새 폴더 추가하기',
      helpTitle: '폴더 : 문서 관리를 체계적으로',
      helpTxt1: '따로 관리해야 할 문서들을 폴더에 넣어 보세요.<br>문서 관리가 더욱 편리해 집니다.',
      helpImg: {
        one: '폴더 선택',
        imgAltOne: '문서 선택 이미지',
        two: '원하는 폴더로 지정',
        imgAltTwo: '폴더 지정 이미지',
        three: '문서 모아보기',
        imgAltThree: '폴더 필터 이미지'
      },
      setModal: {
        title: '폴더 설정',
        emptyMsg: '<p> 폴더가 없습니다. <br> 폴더를 추가하여 문서를 <br> 정리하세요. </ p>'
      },
      newFolder: '새 폴더',
      uncate: '미분류 문서 보기',
      all: '모든 문서로',
      folder: {
        confirm: {
          title: '폴더 삭제',
          content: '<p>새 폴더 폴더를 삭제하시겠습니까?<br>폴더는 삭제되며, 폴더속 문서는 삭제되지 않습니다.</p>'
        },
        error: {
          exist_name: '이미 사용중인 폴더 이름입니다.',
          no_mod: '파일 이름을 수정하십시오'
        }
      },
      folderNameLen: '1자 이상 14자 이하로 입력해 주세요.',
      status: {
        all: '전체 문서',
        need_signing: '내서명필요',
        wait_for_signing: '서명대기중',
        completed: '완료된문서',
        rejected: '거절됨',
        signing_cancelled: '서명취소됨',
        request_cancelled: '요청취소됨',
        reserved: '전송대기중',
        reservation_cancelled: '예약취소됨',
        on_going: '내서명필요',
        pay_all: '전체결제',
        pay_state_request: '결제요청',
        pay_state_complete: '결제완료',
        pay_state_cancel_request: '취소요청',
        pay_state_cancel_approval: '결제취소',
        pay_state_waiting: '결제대기중',
        pay_state_cancel_partial_approval: '부분취소'
      },
      statusPay: {},
      search: {
        title: '문서 이름',
        writer: '서명 요청자',
        signer: '서명 참여자',
        input_placeholder: '100자 이내로 검색해 주세요.'
      },
      download: '목록 엑셀로 내려받기',
      list: {
        state: '상태',
        name: '문서 이름',
        folder: '폴더',
        last_active_time: '마지막 활동 시간',
        action: '메뉴',
        button: {
          resend: '서명요청 알림 재전송',
          remind_complete: '서명완료 알림 재전송',
          time: '서명 유효기간 변경'
        },
        resend_request_title: '서명요청 알림 재전송',
        resend_request_content: '{name} 에 대한 서명요청 알림을 재전송하시겠습니까?',
        resend_request_success: {
          title: '알림 재전송 완료',
          content: '{name} 에 대한 서명요청 알림이 재전송되었습니다.'
        },
        resend_complete_title: '서명완료 알림 재전송',
        resend_complete_content: '{name} 에 대한 서명완료 알림을 재전송하시겠습니까?',
        resend_complete_success: {
          title: '알림 재전송 완료',
          content: '{name} 에 대한 서명완료 알림이 재전송되었습니다.'
        },
        resend_error: '재전송에 실패했습니다.',
        resend_many: {
          title: '서명 알림 재전송 안내',
          content: '선택한 모든 문서의 현재 서명 차례 참여자에게 알림을 다시 전송하시겠어요?',
          success: '선택한 모든 문서의 현재 서명 차례 참여자에게 서명 알림을 재전송했습니다.'
        },
        portal: {
          title: '서명 유효기간 변경',
          content_m: '선택한 모든 문서의 현재 서명 참가자 서명 유효 기간 현재 시점부터의 만료 날짜',
          content_one: '{name}의 서명 유효기간 <br> 만료일이 현재 시점으로부터',
          content_two: '일 후로 변경됩니다.',
          content_three: '변경 후 만료일:  {date} (UTC+09)',
          error: '1일 이상 365일 이하로 입력해 주세요.'
        },
        expiry: {
          title: '서명 유효기간 변경 완료',
          content: '{name}의 서명 유효기간이 변경되어 서명 알림 메일이 다시 전송되었습니다.',
          content_m: '선택한 모든 문서의 현재 서명 차례 참여자의 서명 유효기간 변경을 완료했습니다.',
          error: '만료 시간을 설정하지 못했습니다.'
        }
      },
      bar_hidden: {
        selected_doc_count_one: '선택한 ',
        selected_doc_count_two: ' 개 의 문서를'
      },
      move_modal: {
        title: '폴더 선택',
        text: '문서를 이동할 폴더를 선택해 주세요.'
      },
      del_doc: {
        title: '문서 삭제',
        text1: '선택한 문서를 문서 목록에서 삭제합니다.',
        text2: '삭제된 문서는 설정 메뉴의 삭제된 문서 목록에 보관됩니다.'
      },
      cancel_pay: {
        title: '결제 취소',
        text1: '선택한 계약에 대한',
        text2: '결제취소를 진행하시겠습니까?'
      },
      move_success_modal: {
        title: '이동 완료',
        content: '{count}개의 문서를 {team}의 ‘{folder}’(으)로 이동하였습니다.',
        content_non_org: '{count}개의 문서를 \'{folder}\'(으)로 이동하였습니다.'
      },
      drop: {
        need_signing: '서명 필요',
        expires: '만료됨',
        wait: '대기 중',
        signed: '서명 완료',
        decline: '거절',
        checking: '내용 확인하기',
        cancel: '서명 요청 취소',
        more: '서명 진행정보 더 보기 ...',
        deleted: 'deleted',
        rejected: '거절됨',
        request_cancelled: '요청 취소',
        cancel_payment: '결제 취소하기',
        request_payment: '결제 요청하기',
        re_request_payment: '결제 재요청하기',
        title: {
          need_signing: '나의 서명을 기다리고 있습니다.',
          wait_for_signing: '상대방의 서명을 기다리는 중',
          completed: '문서의 모든 서명이 완료되었습니다.',
          rejected: '{name} 님이 서명 요청을 거절하였습니다.',
          signing_cancelled: '{name} 님이 서명 요청을 취소하였습니다.',
          request_cancelled: '{name} 님이 서명 요청을 취소하였습니다.',
          reservation_cancelled: '예약 전송이 취소된 내역입니다.'
        }
      },
      detail: {
        title: '서명 진행 정보',
        title_pay: '서명/결제 진행 정보',
        doc_title: '문서 정보',
        doc_name: '문서 이름',
        doc_id: '문서 ID',
        sign: '서명 진행 상태',
        doc_status: {
          need_signing: '서명 진행 중',
          wait_for_signing: '서명 진행 중',
          completed: '완료됨',
          signing_cancelled: '서명 요청 취소됨',
          request_cancelled: '서명 요청 취소됨',
          rejected: '거절됨',
          on_going: '서명 진행 중',
          reserved: '전송대기중',
          reservation_cancelled: '예약취소됨'
        },
        decline: '거절한 참여자',
        decline_reason: '서명 거절 사유',
        stand: '기준 시간',
        stand_time: '(UTC +09:00) 한국 표준시',
        sign_request_title: '서명 요청자 정보',
        sign_status: '인증 상태',
        status: {
          all: '모든 상태',
          need_signing: '내 서명 필요',
          wait_for_signing: '서명 대기 중',
          completed: '유캔싸인 가입 인증 완료',
          rejected: '거절됨',
          signing_cancelled: '서명 취소됨',
          request_cancelled: '요청 취소됨',
          reserved: '전송대기중',
          reservation_cancelled: '예약취소됨'
        },
        sign_info_title: '서명 참여자 정보',
        sign_info_process: {
          txt: '진행 상태'
        },
        sign_info_validity: '서명 유효기간',
        sign_info_status: {
          need_to_sign: '서명 필요',
          signed: '서명 완료',
          expired: '서명 유효기간 만료',
          refuse_to_sign: '서명 거절',
          wait: '대기 중'
        },
        sign_auth: '추가 인증 정보',
        auth_type: {
          email: '접근암호 인증',
          password: '접근이메일 인증',
          mobile: '휴대폰 본인 인증 ({name} {mobile})'
        },
        annex: {
          title: '첨부파일 정보'
        },
        history: {
          title: '진행 이력',
          dec: '자동 진행'
        },
        download: '문서 다운로드',
        view: '감사 추적 인증서 보기',
        pay_title: '결제 정보',
        pay_receipt: '영수증 보기',
        pay_mulno: '결제번호',
        pay_prdname: '상품명',
        pay_price: '결제금액',
        cancel_price: '취소금액',
        pay_status: '결제상태',
        pay_requested_at: '결제요청시간',
        pay_paid_at: '결제완료시간',
        pay_canceled_at: '결제취소시간',
        pay_participant_info: '결제대상자 정보',
        pay_partialcancel_title: '부분취소'

      },
      refuse: {
        title: '서명 거절하기',
        content: '정말 서명을 거절하시겠습니까?<br>서명을 거절할 경우 더 이상 <br>서명이진행되지 않습니다.',
        placeholder: '거절사유를 입력해주세요. 입력한 내용은 서명 요청자 및 모든 서명 참여자에게 전송됩니다.',
        title_success: '서명 거절',
        success: '서명을 거절했습니다.',
        error: '실패를 거부',
        msg: '거절 사유를 2자 이상 입력해 주세요.'
      },
      cancel: {
        title: '서명 요청 취소하기',
        sub_title: '취소 사유',
        content: '서명 요청을 취소하면, 서명이 중단되며<br>취소 사유가 요청자와 모든 참여자에게 전송됩니다.',
        placeholder: '2자 이상 200자 이하',
        checkbox: '취소한 문서는 요청자 {name} 제외한 모든 참여자가 볼 수 없도록 설정합니다.',
        title_success: '서명 거절',
        success: '서명 요청을 취소하였습니다.',
        error: '실패를 거부',
        msg: '취소 사유를 2글자 이상 입력해야 합니다.'
      }
    }
  },
  signing: {
    orderTip: {
      one: '첫 번째 서명 참여자',
      two: '두 번째 서명 참여자',
      three: '세 번째 서명 참여자',
      four: '네 번째 서명 참여자',
      five: '다섯 번째 서명 참여자'
    },
    signer: {
      placeholder: {
        name: '이름 또는 회사명',
        email: '이메일 주소',
        kakao: '\'-\' 없이 숫자만 입력',
        roleName: '역할'
      }
    }
  },
  message: {
    test: '{msg} 이것은 테스트 메시지입니다'
  },
  settings: {
    title: '설정',
    mine: {
      title: '내 정보',
      my_information: '내 정보',
      change_company_button: '변경하기',
      change_name_tip: '변경할 이름',
      change_name_button: '저장하기',
      emmail_tip: '계정 이메일',
      password_tip: '비밀번호',
      old_password_tip: '이전 비밀번호',
      old_password_placeholder: '영어, 숫자 또는 특수문자 조합 20자 이내',
      new_password_tip: '새 비밀번호',
      new_password_placeholder: '영어, 숫자 또는 특수문자 조합 10~20자 이내',
      confirm_password_tip: '새 비밀번호 확인',
      confirm_password_placeholder: '영어, 숫자 또는 특수문자 조합 10~20자 이내',
      change_password_button: '저장하기',
      error: {
        password_not_same: 'Two passwords are not matched'
      },
      'deleted documents': '삭제된 문서',
      'role/team': '역할/소속팀'
    },
    payment: {
      title: '결제 정보'
    },
    organization: {
      title: '조직 정보',
      top_tip: '조직을 생성하고, 팀을 구성하여 계약 업무를 효율적으로 관리해 보세요.<br />부계정 별로 문서 조회 및 수정 권한을 제어하여 중요한 문서를 보안할 수 있어요.',
      disabled: '해당 기능은 현재 비활성화 되어있는 상태입니다.',
      'organization management': '조직 관리',
      'organization name': '조직 이름',
      'create an organization': '조직 생성하기',
      'organization creation guide': '조직 생성 안내',
      'please check the cautions when creating an organization': '조직 생성 시 주의사항을 확인해 주세요.',
      'the email above will be the account email for the organization administrator': '위 이메일이 조직 관리자의 계정 이메일이 됩니다.',
      'can not admin email': '조직 생성 이후 조직 관리자 이메일은 변경할 수 없습니다.',
      'Existing documents and templates are automatically transferred and are still available to your organization': '기존 문서와 템플릿은 자동 이전되어 조직에서 그대로 이용할 수 있습니다.',
      'Deleted documents are not automatically transferred, so please recover them before your organization is created if necessary': '삭제한 문서는 자동 이전되지 않기 때문에 필요 시 조직 생성 이전에 복구하시기 바랍니다.',
      'i have checked all of the above': '위 내용을 모두 확인했습니다.',
      'name to change': '변경할 이름',
      'organization manager': '조직 관리자',
      'deleted organizational documents': '삭제된 조직 문서',
      'you can check the list of deleted documents or recover documents': '삭제된 문서 목록을 확인하거나, 문서를 복구할 수 있습니다.',
      'view the list of deleted documents': '삭제된 문서 목록 보기'
    },
    recover_deleted_doc: {
      'deleted document': '삭제된 문서',
      'document name': '문서 이름',
      'please search within 100 characters': '100자 이내로 검색해 주세요.',
      'document recovery': '문서 복구',
      'completely delete': '완전 삭제',
      filter: {
        'file name': '문서 이름',
        participant: '서명 참여자',
        requester: '서명 요청자'
      }
    },
    membership: {
      title: '구성원 정보'
    },
    share: {
      title: '기본 공유 관리'
    },
    customize: {
      title: '맞춤 브랜딩',
      top_tip: '이메일 양식을 브랜드에 맞게 커스터마이징하여 사용할 수 있습니다.',
      email: {
        title: '이메일',
        tip: '서명 요청자와 참여자에게 발송되는 이메일의 로고 이미지, 스타일, 소개글 등을 변경할 수 있습니다.',
        logo_title: '로고이미지',
        address_title: '로고링크',
        desc_title: '설명글',
        bar_color_title: '상단/하단바 색상',
        button_color_title: '버튼 색상',
        text_color_title: '버튼 글자 색상',
        change_button: '변경하기'
      },
      sender: {
        title: '발신자 정보',
        tip: '서명 요청자와 참여자에게 발송되는 이메일의 보내는 사람 이름을 변경할 수 있습니다.',
        name_title: '보내는 사람 이름',
        change_button: '저장하기'
      },
      info: {
        title: '이메일 스타일',
        logo_title: '로고 이미지',
        change_logo_button: '변경하기',
        address_title: '로고 링크',
        desc_title: '설명글',
        bar_color_title: '상단/하단바 색상',
        button_color_title: '버튼 색상',
        text_color_title: '버튼 글자 색상',
        cancel_button: '취소',
        ok_button: '저장하기'
      }
    },
    developer: {
      title: '개발자',
      top_tip: '유캔싸인 API로 기업 내 서비스에 자체 계약시스템을 구축하세요!',
      inquiry1: '해당 페이지는 개발자 전용 페이지 입니다.',
      inquiry2: '개발자 기능을 이용하고 싶으신 분은 챗봇을 통해 상담 바랍니다.',
      api_key: 'API 키',
      copy_success: '클립보드에 저장되었습니다.',
      copy: '복사',
      reissue_info: '갱신은 만료 한달전에 가능합니다',
      reissue: '토큰 갱신',
      total_call: 'API 호출 횟수',
      disabled: '해당 기능은 현재 비활성화 되어 있습니다.',
      show_api_doc: 'API 문서 보기'
    },
    external_link: {
      title: '외부 서비스 연동'
    }
  },
  edit_doc: {
    upload_button: '편집할 문서 업로드',
    search_placeholder: '편집문서 이름을 입력해 주세요.',
    col_name: '편집 문서 이름',
    col_state: '상태',
    col_active_time: '마지막 활동 시간',
    page_sub: '페이지',
    item: {
      edit: '문서 편집',
      send: '서명 요청',
      create_template: '템플릿 제작',
      download: '다운로드',
      delete: '문서 삭제',
      state: {
        available: '편집 됨'
      }
    },
    selection_text: '선택한 {number}개의 편집 문서를 :',
    delete_tip: '삭제',
    delete_cancel_tip: '선택 해제'
  },
  template: {
    add_button: '템플릿 만들기',
    search_placeholder: '템플릿 이름을 입력해 주세요.',
    delete_button: '삭제',
    unselect_button: '선택해제',
    col_name: '템플릿 이름',
    col_create_time: '생성 시간',
    col_last_edit_time: '마지막 수정 시간',
    item: {
      send: '서명 요청',
      sendpay: '서명/결제 요청',
      edit: '템플릿 수정',
      copy_sample: '내 템플릿 제작',
      multiple_sign: '대량전송 시작',
      delete: '템플릿 삭제'
    }
  },
  bulk_transmit: {
    add_button: '새 대량전송 시작하기',
    col_name: '대량전송 이름',
    col_state: '상태',
    col_start_time: '전송 시작일',
    item: {
      view: '전송 자세히 보기',
      edit: '이름 변경',
      delete: '삭제하기'
    },
    change_name: {
      title: '대량전송 이름 변경',
      desc: '변경할 이름을 입력하신 후 확인 버튼을 눌러주세요.',
      placeholder: '대량전송 이름을 2자 이상 입력해 주세요.',
      cancel: '취소',
      ok: '확인'
    },
    excel: {
      title: '대량전송시작하기',
      desc: '"{name}" 으로 대량전송을 시작합니다.',
      download: '1. 설정용 엑셀 파일 다운로드',
      view: '2. 입력란 위치 확인하기',
      upload: '3. 엑셀 업로드하기',
      change_expired_time: '서명 유효기간 변경'
    },
    test: {
      title: '테스트 발송 결과',
      failed: '전송 실패',
      success: '전송 완료',
      unit: '개'
    },
    detail: {
      template_title: '템플릿',
      view_template: '템플릿 확인하기',
      state_title: '상태',
      unit: '개',
      'test email': '테스트 발송',
      upload_excel: '엑셀 재 업로드',
      progress: '진행 내용',
      send_failed: '전송 실패',
      send_success: '전송 완료',
      cancel_all_request: '모든 전송 요청 취소',
      cancel_failed: '취소 실패',
      cancel_success: '요청 취소됨',
      folder: '전송 문서 폴더',
      start_request: '전송 시작',
      done: '완료',
      cancelled: '요청 취소됨',
      preview: '미리보기'
    },
    status: {
      pending: '전송 대기',
      ready: '준비 중',
      cancelled: '요청 취소',
      done: '전송 완료',
      delete: '삭제',
      started: '전송 시작',
      success: '전송 완료',
      'cancelling ready': '취소 대기',
      'cancelling failed': '취소 실패',
      failed: '전송 실패',
      pause: '중단'
    },
    change_expired_time: {
      title: '서명 유효기간 변경',
      desc_prefix: '각 서명 참여자는 요청 메시지 수신 후,',
      desc_subfix: '일 내에 서명을 완료해야합니다.',
      close: '닫기',
      change: '변경하기'
    }
  },
  sign_stamp: {
    list: {
      add_signature: '사인 추가하기',
      add_stamp: '도장 추가하기',
      load_more: '서명 더 보기'
    }
  },
  point: {
    tip: '계약요청 1건당 1포인트가 사용됩니다.',
    lastPoint: '잔여포인트',
    point: '포인트',
    toChargeLink: '충전하기',
    toListLink: '충전 및 사용내역'
  },
  sub_account: {
    title: '부계정 관리',
    team: {
      title: '팀 정보',
      add: '팀 추가',
      table: {
        name: '팀 이름',
        member: '구성원',
        management: '관리'
      },
      delete_confirm: '"{name}"을 삭제하시겠습니까? 해당팀에 포함되어 있던 구성원들은 기본팀으로 전환됩니다.',
      edit: {
        name_length_error: '팀 이름을 1-10자 이내로 입력해 주세요.',
        duplicate_name: '이미 사용 중인 팀 이름입니다.'
      }
    },
    account: {
      title: '부계정 정보',
      add: '부계정 추가',
      table: {
        id: '아이디',
        name: '이름',
        email: '이메일',
        status: '상태',
        team: '소속팀',
        role: '역할',
        permission_coverage: '권한 적용 범위',
        permission_settings: '권한 설정 내용',
        management: '관리'
      },
      delete_confirm: '"{name}" 부계정을 삭제하시겠습니까?',
      edit: {
        id: '아이디',
        id_error: {
          'the input field is empty': '아이디를 입력하세요.',
          'the first letter entered is a number': '아이디는 알파벳 소문자로 시작해야 합니다.',
          'the input character is less than 4 characters': '아이디는 4자 이상의 영문과 숫자로 입력해 주세요.',
          'It is an available ID': '사용 가능한 아이디입니다.',
          'Please input name': '이름을 입력하세요.',
          'Email is not correct': '이메일을 다시 확인해 주세요.'
        },
        'duplicate check': '중복 확인',
        name: '이름',
        email: '이메일',
        email_error: {
          'duplicated email': '이미 사용중인 이메일입니다.',
          'It is an available Email': '사용 가능한 이메일입니다.'
        },
        password: '비밀번호',
        team: '소속팀',
        role: '역할',
        permission: '권한 설정',
        permission_scope_team: '소속팀',
        permission_scope_all: '전체',
        permission_read_and_write: '문서조회 및 수정가능',
        permission_read_only: '전체',
        permission_summary: '[소속팀] : 소속되어 있는 팀 문서<br />[전체] : 조직 내 전체 문서<br />* [전체]로 설정한 경우, [소속팀]의 문서조회 및 수정가능 권한이 포함됩니다.'
      }
    },
    correction_btn: '수정',
    delete_btn: '삭제'
  },
  developer: {
    index: {
      title: '소개'
    },
    auth: {
      title: '인증 & 인가'
    },
    response_code: {
      title: '응답 코드'
    },
    sign_request: {
      title: '서명 요청'
    },
    webhook: {
      title: '웹훅'
    },
    embedding: {
      title: '임베딩 페이지'
    },
    oauth: {
      title: 'OAUTH'
    },
    update_log: {
      title: '업데이트 로그'
    }
  }
}
