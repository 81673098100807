<template>
  <div class="ucs-default-wrapper">
    <DeveloperSider :device="device" ref="sider"/>
    <section class="container">
      <div class="a-header">
        <h2>{{ title }}</h2>
      </div>
      <section class="a-cont">
        <div class="cont-wrap">
          <router-view></router-view>
        </div>
      </section>
    </section>
  </div>
  <a-back-top style="color: #ffffff">TOP</a-back-top>
</template>

<script>
import DeveloperSider from 'components/default/sider/DeveloperSider.vue'
import { useStore } from 'vuex'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import 'vue-code-highlight/themes/duotone-sea.css'
import 'vue-code-highlight/themes/window.css'

export default {
  components: {
    DeveloperSider
  },
  setup () {
    const store = useStore()
    const device = ref(store.state.app.device)
    const { t } = useI18n()
    const route = useRoute()
    const handleLayouts = () => {
      const width = document.body.getBoundingClientRect().width
      const isMobile = width - 1 < 768
      const deviceOne = isMobile ? 'mobile' : 'desktop'
      store.dispatch('setDevice', deviceOne)
      device.value = deviceOne
    }

    onMounted(() => {
      handleLayouts()
      window.addEventListener('resize', handleLayouts)
    })
    onUnmounted(() => {
      window.removeEventListener('resize', handleLayouts)
    })

    const title = computed(() => {
      return t(route.meta.title)
    })

    return {
      device,
      title
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep {
  @import '../../assets/styles/developer.less';
}

.ant-back-top {
  background-color: #000;
  font-size: 16px;
  right: 22px;
  bottom: 96px;
  z-index: 10;
  width: 60px;
  height: 60px;
  border-radius: 26px;
  line-height: 60px;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .ant-back-top {
    display: none;
  }
}
</style>
