import { createStore } from 'vuex'
import user from './modules/user'
import app from './modules/app'
import documents from './modules/documents'
import notice from './modules/notice'
import settings from './modules/settings'

const store = createStore({
  modules: {
    user,
    app,
    documents,
    notice,
    settings
  }
})
export default store
