import { reactive, toRefs } from 'vue'
import { getSamples, getSampleTemplates } from 'api/sign'
import { list as getMyTemplates } from 'api/template'

export const useSamples = () => {
  const state = reactive({
    loading: true,
    samples: [],
    downloadLink: ''
  })

  const fetchData = () => {
    state.loading = true
    return getSamples().then(res => {
      state.samples = res.result.list
      state.downloadLink = res.result.download
    }).finally(() => {
      state.loading = false
    })
  }

  fetchData()

  return { ...toRefs(state) }
}

export const useSampleTemplate = () => {
  const state = reactive({
    loading: true,
    sampleTemplates: [],
    downloadLink: 'https://ucansign.s3.ap-northeast-2.amazonaws.com/sample-documents/sample_documents.zip'
  })

  const fetchData = () => {
    state.loading = true
    return getSampleTemplates().then(res => {
      state.sampleTemplates = res.result
    }).finally(() => {
      state.loading = false
    })
  }

  fetchData()

  return { ...toRefs(state) }
}

export const useMyTemplate = () => {
  const state = reactive({
    loading: true,
    myTemplates: []
  })

  const fetchData = () => {
    state.loading = true
    return getMyTemplates({
      page: 1,
      limit: 100
    }).then(res => {
      state.myTemplates = res.result.list
    }).finally(() => {
      state.loading = false
    })
  }

  fetchData()

  return { ...toRefs(state) }
}
