<template>
  <router-view></router-view>
</template>

<script setup>
import { onBeforeMount, onUnmounted } from 'vue'
import { clearLinkSessions, clearUserInfo, signFromLink } from '@/utils/helper'
import { useStore } from 'vuex'
import { onBeforeRouteLeave } from 'vue-router'

let goToContract = false
const store = useStore()

onBeforeMount(() => {
  store.dispatch('resetEditData')
})

onBeforeRouteLeave(to => {
  if (to.name === 'Contract') goToContract = true
  store.dispatch('resetEditData')
})

onUnmounted(() => {
  store.commit('SET_CURRENT_PDF', null)
  const [isFromLink] = signFromLink()
  if (isFromLink && !goToContract) {
    clearLinkSessions()
    clearUserInfo()
  }
})
// eslint-disable-next-line no-undef
ChannelIO('hideChannelButton')
</script>

<style lang="less" scoped>

</style>
