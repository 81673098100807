export default {
  common: {
    set: 'Set',
    complete: 'Complete',
    cancel: 'Cancel',
    modify: 'Modify',
    delete: 'Delete',
    confirm: 'Confirm',
    move: 'Move',
    change: 'Change',
    refuse: 'Refuse',
    logout: 'Logout',
    langs: 'Languages',
    copyright: 'UCanSign ©2021 Created by Atreez',
    error: {
      login: 'Login information has expired',
      empty: 'Operation object is empty'
    }
  },
  user: {
    // sns
    sns: {
      tip: 'Simple login to SNS account',
      facebook_button: 'Facebook',
      google_button: 'google',
      naver_button: 'naver',
      error: {
        get_email_error: 'Can not get your email'
      }
    },

    // login sign in
    login_title: 'E-Contract Simple Service, Youcan Sign',
    email_input_placeholder: 'email',
    password_input_placeholder: 'password',
    login_submit: 'login',
    forget_password_tip: 'Forgot your password? ',
    forget_password_button: 'Find password',
    join_tip: 'If you don\'t have an account, sign up now! ',
    join_button: 'Sign up as a member',
    wrong_email: 'Please input correct email',
    empty_password: 'Please input password',
    login: {
      error: {
        not_exist: 'This account is not existed, please register firstly'
      }
    },

    // forget password
    forget_title: 'Forgot your password?',
    forget_email_placeholder: 'enter email address',
    forget_email_submit: 'send email',
    forget_bottom_text: 'A link to reset your password will be sent to the email address you signed up for.',
    forget_wrong_email: 'Please input correct email',
    forget_empty_email: 'Email can not be empty',
    forget_email_send: 'Email send successful',

    // join
    join_title: 'If you register as a member now, you can use it for free.',
    join_name_placeholder: 'name',
    join_password_placeholder: 'password',
    join_email_placeholder: 'work email',
    join_all_agree: 'agree with all',
    join_agree: 'Terms of use for membership',
    join_agree_personal: 'Consent to collection and use of personal information',
    join_agree_market: 'Agree to receive marketing information',
    join_agree_link: 'view',
    join_submit: 'member registration',
    login_tip: 'If you have already signed up? ',
    login_button: 'login',

    error: {
      empty_user_name: 'please input user name',
      user_name_length: 'user name\'s length should be in 1~20',
      empty_password: 'Please input password',
      wrong_password_format: 'Password must be a string has 8-16 words and contains digit, character and symbol.',
      confirm_password_empty: 'Please input the password again',
      confirm_password_wrong: 'Two inputs don\'t match!',
      empty_email: 'Email can not be empty',
      wrong_email: 'Please input correct email',
      check_all_privacy: 'Please agree all privacy'
    },

    reset_password: {
      title: 'Reset password',
      email_placeholder: 'Email',
      password_placeholder: 'Please input new password',
      confirm_password_placeholder: 'Confirm password',
      submit: 'Submit'
    },

    email: {
      request: {
        title: 'Email validation',
        tip: 'One validation email already sent to',
        tip1: 'If you did\'t receive it, you can click the blow button to send again',
        button: 'Send again'
      },
      validation: {
        success: 'Validate email successful'
      }
    }
  },
  documents: {
    sider: {
      sign: 'Signature',
      document: 'Document',
      demo: 'Demo'
    },
    header: {
      help: 'Help',
      document: 'Document',
      template: '템플릿',
      bulkTransmit: '대량전송',
      docuEdit: '문서편집기',
      signStamp: '사인/도장',
      settings: '설정',
      point: '포인트 관리',
      pointList: '충전 및 사용내역',
      pointCharge: '충전하기',
      demo: 'Demo',
      orgIndex: '조직 전체 문서',
      orgOwnerIndex: '내 담당 문서',
      teamIndex: '팀 문서',
      teamOwnerIndex: '내 담당 문서',
      teamTemplate: '팀 템플릿'
    },
    content: {
      folderBtn: 'Folder list',
      emptyFolderMsg: {
        msg1: 'There is no folder.',
        msg2: 'Try adding a folder to ',
        msg3: 'organize your documents'
      },
      newBtn: 'Add New Folder',
      helpTitle: ' Folder: systematically manage documents',
      helpTxt: 'Put the documents you need to manage separately in a folder.<br>Document management becomes more convenient.',
      helpImg: {
        one: 'Select folder',
        imgAltOne: 'Document selection image',
        two: 'Specify the desired folder',
        imgAltTwo: 'Folder designation image',
        three: 'Collecting documents',
        imgAltThree: 'Folder filter image'
      },
      setModal: {
        title: 'Folder settings',
        emptyMsg: '<p>There are no folders.<br>Add folders to keep your documents <br>organized.</p>'
      },
      newFolder: 'New Folder',
      uncate: 'Unclassified docs',
      all: 'All docs',
      folder: {
        confirm: {
          title: 'Delete folder',
          content: '<p>Are you sure you want to delete the new folder folder?<br>The folder will be deleted, the documents in the folder will not be deleted.</p>'
        },
        error: {
          exist_name: 'This is the folder name already in use.',
          no_mod: 'Please modify the file name'
        }
      },
      folderNameLen: 'Please enter 1 character and 14 characters.',
      status: {
        all: 'All State',
        need_signing: 'Need my signature',
        wait_for_signing: 'Waiting for other party\'s signature',
        completed: 'Completed document',
        rejected: 'Rejected',
        signing_cancelled: 'Signature canceled',
        request_cancelled: 'Request cancelled',
        reserved: 'reserved'
      },
      search: {
        title: 'Document name',
        writer: 'Signature requester',
        signer: 'Signing participant',
        input_placeholder: 'Please search within 100 characters.'
      },
      download: 'Download list to excel',
      list: {
        state: 'State',
        name: 'Document name',
        folder: 'Folder',
        last_active_time: 'Last active time',
        action: 'Action',
        button: {
          resend: 'Resend signature notification mail',
          time: 'Change of validity period of signature'
        },
        resend_title: 'Resend notification mail',
        resend_content: 'Would you like to <br>resend the signature notification e-mail to {name}?',
        resend_success: {
          title: 'Notification resend complete',
          content: 'Resent the signature notification email to {name}.'
        },
        resend_error: 'Failed to send mail',
        resend_many: {
          title: 'Notice of resending signature notification',
          content: 'Would you like to resend notifications to participants in the current signature turn of all selected documents ?',
          success: 'Resent signature notification to participants in the current signature turn of all selected documents.'
        },
        portal: {
          title: 'Change of validity period of signature',
          content_m: 'Current Signatures of All Selected Documents Participant\'s Signature Validity Expiration Date from the current point in time',
          content_one: '{name}\'s signature expiration <br> date from the current point of time',
          content_two: 'It will change in days.',
          content_three: 'Expiration date after change: {date} (UTC+09)',
          error: 'Only can enter 1 to 365'
        },
        expiry: {
          title: 'Signature validity change completed',
          content: '{name}\'s signature validity period has changed, and the signature notification email has been sent again.',
          content_m: 'You have completed the change of the current signature turn participant\'s signature validity period for all selected documents.',
          error: 'Failed to set expiration time'
        }
      },
      bar_hidden: {
        selected_doc_count_one: 'Select ',
        selected_doc_count_two: ' documents:'
      },
      move_modal: {
        title: 'Select folder',
        text: 'Please select a folder to move the document to.'
      },
      del_doc: {
        title: 'Delete document',
        text: '<p>Deleted documents <br>disappear from the Organization Documents list and <br>are kept in the Deleted Documents list.</p>'
      },
      move_success_modal: {
        title: 'Complete the move',
        content: '{count} documents have been moved to \'{team}\' in {folder}.',
        content_non_org: '{count} document has been moved to \'{folder}\''
      },
      drop: {
        need_signing: 'Need to sign',
        expires: 'Expires',
        wait: 'waiting',
        signed: 'Signature',
        decline: 'Decline',
        checking: 'Checking the content',
        cancel: 'Canceling a signature request',
        more: 'More signing progress information ...',
        deleted: 'Deleted',
        rejected: '거절됨',
        title: {
          need_signing: 'Waiting for my signature.',
          wait_for_signing: 'Waiting for other party\'s signature',
          completed: 'All signatures of the document have been completed.',
          rejected: '{name} declined the request to sign.',
          signing_cancelled: '{name} canceled the request for signature.',
          request_cancelled: '{name} canceled the request for signature.'
        }
      },
      detail: {
        title: 'Signature progress information',
        doc_title: 'Document information',
        doc_name: 'Document name',
        doc_id: 'Document ID',
        sign: 'Signing progress',
        doc_status: {
          need_signing: 'Signing in progress',
          wait_for_signing: 'Signing in progress',
          completed: 'Completed',
          signing_cancelled: 'Signature request canceled',
          request_cancelled: 'Signature request canceled',
          rejected: 'Rejected',
          on_going: 'Signing in progress'

        },
        decline: 'Participants who declined',
        decline_reason: 'Reasons for refusal to sign',
        stand: 'Standard Time',
        stand_time: 'Based on user computer time',
        sign_request_title: 'Signature requester information',
        sign_status: 'Certification status',
        status: {
          completed: 'All signed sign-up verification completed'
        },
        sign_info_title: 'Signature Participant Information',
        sign_info_process: {
          txt: 'Process'
        },
        sign_info_validity: 'Signature validity period',
        sign_info_status: {
          need_to_sign: 'Need to sign',
          signed: 'Signed',
          expired: 'Signature expiration date',
          refuse_to_sign: 'Refusal to sign',
          wait: 'Waiting'
        },
        sign_auth: 'Additional authentication',
        auth_type: {
          email: 'Access email authentication',
          password: 'Access password authentication',
          mobile: 'Mobile phone identification ({name} {mobile})'
        },
        annex: {
          title: 'Attached file information'
        },
        history: {
          title: 'Progress history',
          dec: 'Automatic progress'
        },
        download: 'Document Download',
        view: 'Viewing the audit trail certificate'

      },
      refuse: {
        title: 'Refusing to sign',
        content: 'Are you sure you want to refuse to sign? If you<br> decline to sign, no further signing will proceed.',
        placeholder: 'Please enter the reason for refusal.  Your input will be sent to the signing requester and all signing participants.',
        title_success: 'Refusal to sign',
        success: 'I declined to sign.',
        error: 'Refusal to fail',
        msg: 'Please enter at least 2 characters for rejection.'
      },
      cancel: {
        title: 'Canceling a signature request',
        sub_title: 'Reason for cancellation',
        content: 'If you cancel the signature request, the signature is stopped and the <br>reason for the cancellation is sent to the requester and all participants.',
        placeholder: '2 or more and 200 or less',
        checkbox: 'The canceled document is set so that all participants except the requester {name}. cannot see it. ',
        title_success: 'Signing request canceled',
        success: 'I canceled my signature request.',
        error: 'Refusal to fail',
        msg: 'Please enter at least 2 characters for cancel.'
      }

    }
  },
  signing: {
    orderTip: {
      one: '첫 번째 서명 참여자',
      two: '두 번째 서명 참여자',
      three: '세 번째 서명 참여자',
      four: '네 번째 서명 참여자',
      five: '다섯 번째 서명 참여자'
    },
    signer: {
      placeholder: {
        name: '이름 또는 회사명',
        email: '이메일 주소',
        kakao: '\'-\' 없이 숫자만 입력',
        roleName: '역할'
      }
    }
  },
  message: {
    test: 'This is a test message'
  },
  settings: {
    title: '설정',
    mine: {
      title: '내 정보',
      my_information: '내정보',
      change_company_button: '변경하기',
      change_name_tip: '변경할 이름',
      change_name_button: '저장하기',
      emmail_tip: '계정 이메일',
      password_tip: '비밀번호',
      old_password_tip: '이전 비밀번호',
      old_password_placeholder: '영어, 숫자 또는 특수문자 조합 20자 이내',
      new_password_tip: '새 비밀번호',
      new_password_placeholder: '영어, 숫자 또는 특수문자 조합 10~20자 이내',
      confirm_password_tip: '새 비밀번호 확인',
      confirm_password_placeholder: '영어, 숫자 또는 특수문자 조합 10~20자 이내',
      change_password_button: '저장하기',
      error: {
        password_not_same: 'Two passwords are not matched'
      }
    },
    payment: {
      title: '결제 정보'
    },
    organization: {
      title: '조직 정보'
    },
    membership: {
      title: '구성원 정보'
    },
    share: {
      title: '기본 공유 관리'
    },
    customize: {
      title: '맞춤 브랜딩',
      top_tip: '맞춤 브랜딩 정보를 변경하면, 경영지원의 모든 구성원에게 동시에 적용됩니다!',
      email: {
        title: '이메일',
        tip: '서명 요청자와 참여자에게 발송되는 이메일의 로고 이미지, 스타일, 소개글 등을 변경할 수 있습니다.',
        logo_title: '로고이미지',
        address_title: '로고링크',
        desc_title: '설명글',
        bar_color_title: '상단/하단바 색상',
        button_color_title: '버튼 색상',
        text_color_title: '버튼 글자 색상',
        change_button: '변경하기'
      },
      sender: {
        title: '발신자 정보',
        tip: '서명 요청자와 참여자에게 발송되는 이메일의 보내는 사람 이름을 변경할 수 있습니다.',
        name_title: '보내는 사람 이름',
        change_button: '저장하기'
      },
      info: {
        title: '이메일 스타일',
        logo_title: '로고 이미지',
        change_logo_button: '변경하기',
        address_title: '로고 링크',
        desc_title: '설명글',
        bar_color_title: '상단/하단바 색상',
        button_color_title: '버튼 색상',
        text_color_title: '버튼 글자 색상',
        cancel_button: '취소',
        ok_button: '저장하기'
      }
    }
  },
  edit_doc: {
    upload_button: '편집할 문서 업로드',
    search_placeholder: '편집문서 이름을 입력해 주세요.',
    col_name: '편집 문서 이름',
    col_state: '상태',
    col_active_time: '마지막 활동 시간',
    page_sub: '쪽',
    item: {
      edit: '문서 편집',
      send: '서명 요청',
      create_template: '템플릿 제작',
      download: '다운로드',
      delete: '문서 삭제',
      state: {
        available: '편집 됨'
      }
    },
    selection_text: '선택한 {number}개의 편집 문서를 :',
    delete_tip: '삭제',
    delete_cancel_tip: '선택 해제'
  },
  template: {
    add_button: '템플릿 만들기',
    search_placeholder: '템플릿 이름을 입력해 주세요.',
    delete_button: '삭제',
    unselect_button: '선택해제',
    col_name: '템플릿 이름',
    col_create_time: '생성 시간',
    col_last_edit_time: '마지막 수정 시간',
    item: {
      send: '서명 요청',
      sendpay: '서명/결제 요청',
      edit: '템플릿 수정',
      multiple_sign: '대량전송 시작',
      delete: '템플릿 삭제'
    }
  },
  bulk_transmit: {
    add_button: '새 대량전송 시작하기',
    col_name: '대량전송 이름',
    col_state: '상태',
    col_start_time: '전송 시작일',
    item: {
      view: '전송 자세히 보기',
      edit: '이름 변경',
      delete: '삭제하기'
    },
    change_name: {
      title: '대량전송 이름 변경',
      desc: '변경할 대량전송 이름을 입력하고\n변경하기 버튼을 눌러주세요.',
      placeholder: '대량전송 이름을 2자 이상 입력해 주세요.',
      cancel: '취소',
      ok: '확인'
    },
    excel: {
      title: '대량전송시작하기',
      desc: '"{name}" 으로 대량전송을 시작합니다.',
      download: '1. 설정용 엑셀 파일 다운로드',
      view: '2. 입력란 위치 확인하기',
      upload: '3. 엑셀 업로드하기',
      change_expired_time: '서명 유효기간 변경'
    },
    detail: {
      template_title: '템플릿',
      view_template: '템플릿 확인하기',
      state_title: '상태',
      unit: '개',
      download_excel: '설정용 엑셀 다운로드',
      upload_excel: '엑셀 재 업로드',
      progress: '진행 내용',
      send_failed: '전송 실패',
      send_success: '전송 완료',
      cancel_all_request: '모든 전송 요청 취소',
      cancel_failed: '취소 실패',
      cancel_success: '요청 취소됨',
      folder: '전송 문서 폴더',
      start_request: '전송 시작',
      done: '완료',
      cancelled: '요청 취소됨',
      preview: '미리보기'
    },
    status: {
      pending: '전송 대기',
      ready: '준비 중',
      started: '이동 중',
      done: '전송 완료',
      cancelled: '요청 취소'
    },
    change_expired_time: {
      title: '서명 유효기간 변경',
      desc_prefix: '각 서명 참여자는 요청 메시지 수신 후,',
      desc_subfix: '일 내에 서명을 완료해야합니다.',
      close: '닫기',
      change: '변경하기'
    }
  },
  sign_stamp: {
    list: {
      add_signature: '사인 추가하기',
      add_stamp: '도장 추가하기',
      load_more: '서명 더 보기'
    }
  },
  point: {
    tip: '서명 참여자 1명당 1포인트가 사용됩니다.',
    lastPoint: '잔여포인트',
    point: '포인트',
    toChargeLink: '충전하기',
    toListLink: '충전 및 사용내역'
  }
}
