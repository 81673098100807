import createStorage from 'utils/storage'
import { bannerLastClosedAt, bulkRequestNotice, hideSignCreatingTutorial, hideSigningTutorial, tooltipMobileSignCreating, tooltipPayment, tooltipPaymentModal, tooltipSignstart } from 'config/config'

const storage = createStorage()
const app = {
  state: {
    isShowLackPointModal: false,
    bannerLastClosedAt: storage.get(bannerLastClosedAt, null),
    hideSigningTutorial: storage.get(hideSigningTutorial, false),
    hideSignCreatingTutorial: storage.get(hideSignCreatingTutorial, false),
    tooltipPayment: storage.get(tooltipPayment, false),
    tooltipSignstart: storage.get(tooltipSignstart, false),
    tooltipMobileSignCreating: storage.get(tooltipMobileSignCreating, false),
    bulkRequestNotice: storage.get(bulkRequestNotice, false),
    tooltipPaymentModal: storage.get(tooltipPaymentModal, false)
  },
  mutations: {
    SET_LACK_POINT_SHOW (state, flag) {
      state.isShowLackPointModal = flag
    },
    SET_BANNER_CLOSED_TIME (state, value) {
      storage.set(bannerLastClosedAt, value)
      state.bannerLastClosedAt = value
    },
    SET_HIDE_SIGNING_TUTORIAL (state, value) {
      storage.set(hideSigningTutorial, value)
      state.hideSigningTutorial = value
    },
    SET_HIDE_SIGN_CREATING_TUTORIAL (state, value) {
      storage.set(hideSignCreatingTutorial, value)
      state.hideSignCreatingTutorial = value
    },
    SET_TOOLTIP_PAYMENT (state, value) {
      storage.set(tooltipPayment, value)
      state.tooltipPayment = value
    },
    SET_TOOLTIP_SIGNSTART (state, value) {
      storage.set(tooltipSignstart, value)
      state.tooltipSignstart = value
    },
    SET_TOOLTIP_MOBILE_SIGN_CREATING (state, value) {
      storage.set(tooltipMobileSignCreating, value)
      state.tooltipMobileSignCreating = value
    },
    SET_BULKREQUEST_NOTICE (state, value) {
      storage.set(bulkRequestNotice, value)
      state.bulkRequestNotice = value
    },
    SET_TOOLTIP_PAYMENT_MODAL (state, value) {
      storage.set(tooltipPaymentModal, value)
      state.tooltipPaymentModal = value
    }
  },
  actions: {
    setBannerCloseTime ({ commit }, time) {
      commit('SET_BANNER_CLOSED_TIME', time)
    },
    setHideSigningTutorial ({ commit }, hideSigningTutorial) {
      commit('SET_HIDE_SIGNING_TUTORIAL', hideSigningTutorial)
    },
    setHideSignCreatingTutorial ({ commit }, hideSignCreatingTutorial) {
      commit('SET_HIDE_SIGN_CREATING_TUTORIAL', hideSignCreatingTutorial)
    },
    setTooltipPayment ({ commit }, tooltipPayment) {
      commit('SET_TOOLTIP_PAYMENT', tooltipPayment)
    },
    setTooltipSignstart ({ commit }, tooltipSignstart) {
      commit('SET_TOOLTIP_SIGNSTART', tooltipSignstart)
    },
    setTooltipMobileSignCreating ({ commit }, tooltipMobileSignCreating) {
      commit('SET_TOOLTIP_MOBILE_SIGN_CREATING', tooltipMobileSignCreating)
    },
    setBulkRequestNotice ({ commit }, bulkRequestNotice) {
      commit('SET_BULKREQUEST_NOTICE', bulkRequestNotice)
    },
    setTooltipPaymentModal ({ commit }, tooltipPaymnetModal) {
      commit('SET_TOOLTIP_PAYMENT_MODAL', tooltipPaymnetModal)
    }
  }
}

export default app
