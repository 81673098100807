<template>
  <UcsModal v-model:visible="visible" :width="650">
    <template #title>
      <h2 v-if="openType === 'template'">탬플릿 만들기</h2>
      <h2 v-else>서명할 문서 선택 <!--<a href="#a" class="link text-gray">도움말</a> --></h2>
    </template>
    <template #content>
      <a-spin :spinning="isShowLoading" size="large">
        <div v-if="!isMobile">
          <div class="modal-nav">
            <ul class="tab-menu tab-nav" role="tablist">
              <li
                :class="{ active: tabIndex === 0 }"
                data-tab="sort"
                rel="computer"
                role="tab"
                @click="changeTab(0)"
              >
                내 컴퓨터
              </li>
              <li
                :class="{ active: tabIndex === 1 }"
                data-tab="sort"
                rel="sample"
                role="tab"
                @click="changeTab(1)"
              >
                계약서 샘플
              </li>
              <!--
              <li
                role="tab"
                :class="{ active: tabIndex === 2 }"
                rel="sample"
                data-tab="sort"
                @click="changeTab(2)"
              >
                샘플 탬플릿
              </li>
              -->
              <li
                v-if="openType !== 'template'"
                :class="{ active: tabIndex === 2 }"
                data-tab="sort"
                rel="sample"
                role="tab"
                @click="changeTab(2)"
              >
                내 탬플릿
              </li>
            </ul>
            <div class="nav-btm">
            <span class="btn-down" role="button" @click="onClickDownload">
              다양한 계약서 양식<br/>다운로드하기
              <svg
                aria-hidden="true"
                data-icon="download"
                fill="#262626"
                focusable="false"
                height="1em"
                viewBox="64 64 896 896"
                width="1em"
              >
                <path
                  d="M505.7 661a8 8 0 0012.6 0l112-141.7c4.1-5.2.4-12.9-6.3-12.9h-74.1V168c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v338.3H400c-6.7 0-10.4 7.7-6.3 12.9l112 141.8zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"
                ></path>
              </svg>
            </span>
            </div>
          </div>
          <!-- //modal-nav -->
          <div id="sort" class="tab-container nav-cont">
            <UploadBox v-show="tabIndex === 0" @endUpload="onEndUpload" @finishUpload="onFinishUpload" @startUpload="startUpload"/>
            <!--<SampleBox v-show="tabIndex === 1" @startUpload="startUpload" @finishUpload="onFinishUpload" @downloadLink="onDownloadlink"/>-->
            <SampleTemplateBox v-show="tabIndex === 1" @downloadLink="onDownloadlink" @finishUpload="onFinishUpload" @startUpload="startUpload"/>
            <MyTemplateBox v-show="tabIndex === 2" @finishUpload="onFinishUpload" @startUpload="startUpload" @toTemplate="onClickTemplate"/>
            <div id="template" class="tab-content"></div>
          </div>
        </div>
        <div v-else class="m-upload">
          <ul class="tab-menu tab-nav" role="tablist">
            <li
              :class="{ active: tabIndex === 0 }"
              data-tab="sort"
              rel="computer"
              role="tab"
              @click="changeTab(0)"
            >
              샘플 탬플릿
            </li>
            <li
              :class="{ active: tabIndex === 1 }"
              data-tab="sort"
              rel="sample"
              role="tab"
              @click="changeTab(1)"
            >
              내 템플릿
            </li>
          </ul>
          <div id="sort" class="tab-container">
            <SampleTemplateBox v-show="tabIndex === 0" @finishUpload="onFinishUpload" @startUpload="startUpload"/>
            <MyTemplateBox v-show="tabIndex === 1" @finishUpload="onFinishUpload" @startUpload="startUpload" @toTemplate="onClickTemplate"/>
          </div>
        </div>
      </a-spin>
    </template>
  </UcsModal>
</template>

<script>
import { computed, reactive, toRefs } from 'vue'
import UploadBox from 'components/signing/UploadBox'
// import SampleBox from './SampleBox'
import SampleTemplateBox from './SampleTemplateBox'
import UcsModal from 'components/common/UcsModal'
import { useRoute, useRouter } from 'vue-router'
import { createEditingDocument } from '@/api/edit'
import { useRouterSwitch } from 'use/sign/useRouterSwitch'
import { useGtag } from 'vue-gtag-next'
import { useMediaQuery } from '@vueuse/core'
import MyTemplateBox from './MyTemplateBox'

export default {
  components: {
    MyTemplateBox,
    UploadBox,
    // SampleBox,
    SampleTemplateBox,
    UcsModal
  },
  props: ['isEditDoc', 'isEditTemplate'],
  setup (props, { emit }) {
    const isMobile = useMediaQuery('(max-width: 768px)').value
    const { event } = useGtag()
    const router = useRouter()
    const route = useRoute()
    const state = reactive({
      visible: false,
      tabIndex: 0,
      isShowLoading: false,
      downloadLink: '',
      showTemplate: true,
      openType: ''
    })
    const show = (type = '') => {
      if (props.isEditDoc === true) {
        state.showTemplate = false
      }
      state.openType = type
      state.visible = true
    }
    const changeTab = tabIndex => {
      state.tabIndex = tabIndex
    }

    const startUpload = () => {
      state.isShowLoading = true
    }

    const onFinishUpload = (curPdf, type) => {
      if (state.openType === 'editDocument') {
        const {
          fileId,
          name
        } = curPdf
        createEditingDocument({
          fileId,
          name
        }).then(res => {
          router.push({
            name: 'DocEditting',
            params: { editingDocumentId: res.result.documentId }
          })
        }).finally(() => {
          state.isShowLoading = false
        })
      } else if (state.openType === 'template') {
        state.isShowLoading = false
        router.push({
          name: 'TemplateEditting',
          params: { templateId: curPdf.documentId ? curPdf.documentId : null }
        })
      } else {
        const isMobile = useMediaQuery('(max-width: 768px)').value
        state.isShowLoading = false
        event(`Direct_서명_문서선택_${type}`)
        router.push({
          name: isMobile ? 'MobileSignCreating' : 'SignCreating',
          params: { documentId: curPdf.documentId ? curPdf.documentId : null }
        })
      }
    }
    const { toTemplatePage } = useRouterSwitch()
    const onClickTemplate = () => {
      toTemplatePage()
      state.visible = false
    }

    const onEndUpload = () => {
      state.isShowLoading = false
    }

    const onDownloadlink = link => {
      state.downloadLink = link
    }

    const onClickDownload = () => {
      window.open(state.downloadLink)
    }

    const isShowTemplateBtn = computed(() => {
      return route.name !== 'Template'
    })

    return {
      isMobile,
      show,
      changeTab,
      startUpload,
      onFinishUpload,
      onClickTemplate,
      onEndUpload,
      onDownloadlink,
      onClickDownload,
      isShowTemplateBtn,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.modal-nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.m-upload {
  padding: 2rem;

  ul {
    display: flex;
    justify-content: space-between;

    li {
      width: 50%;
      border: 1px solid #ebebeb;
      text-align: center;

      &:nth-child(2) {
        border-left: 0
      }
    }
  }
}
</style>
