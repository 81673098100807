import store from 'store'
import engine from 'store/src/store-engine'

import sessionStorage from 'store/storages/sessionStorage'

import defaultPlugin from 'store/plugins/defaults'
import expiredPlugin from 'store/plugins/expire'
import eventsPlugin from 'store/plugins/events'

const sessionStore = engine.createStore([sessionStorage], [defaultPlugin, expiredPlugin, eventsPlugin])

const createStorage = (storageType = undefined) => {
  return storageType === 'sessionStorage' ? sessionStore : store
}

export default createStorage
