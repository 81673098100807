<template>
  <a-modal
    class="onboarding-modal"
    v-model:visible="visible"
    :footer="false"
    :closable="false"
    :keyboard="false"
    :maskClosable="false">
    <button type="button" aria-label="Close" class="custom-modal-close" @click="showConfirmModal=true">
      <span class="ant-modal-close-x">
        <span role="img" aria-label="close" class="anticon anticon-close ant-modal-close-icon">
          <svg focusable="false" class="" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" viewBox="64 64 896 896">
            <path
              d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
          </svg>
        </span>
      </span>
    </button>
    <a-carousel arrows :after-change="onChange" :infinite="false">
      <template #prevArrow>
        <div class="custom-slick-arrow" style="z-index: 1; left:-22px; display: block; width:50px; height:100px; padding:35px 10px; margin-top:-50px;" v-if="currentPage !== 0">
          <left-circle-outlined/>
        </div>
      </template>
      <template #nextArrow>
        <div class="custom-slick-arrow" style="z-index: 1; right:-22px; display: block; width:50px; height:100px; padding:35px 10px; margin-top:-50px;" v-if="currentPage !== 5">
          <right-circle-outlined/>
        </div>
      </template>
      <div>
        <div class="page">
          <img src="@/assets/images/onboard_img01.jpg">
          <h4>
            <strong>3분 만에 완료</strong>되는 유캔싸인 전자계약과 함께<br/>
            <strong>계약 업무 속도 향상</strong>과 <strong>비용 절감</strong>을<br/>모두 경험해 보세요!
          </h4>
        </div>
      </div>
      <div>
        <div class="page">
          <img src="@/assets/images/onboard_img02.jpg">
          <div class="usr-box">
            <span class="bk-bl">계약 생성자</span>
          </div>
          <h3>Step1. 계약 문서 업로드</h3>
          <h4>
            <strong>서명 요청하기 버튼 클릭</strong>하고,<br/>
            <strong>사용하던 계약 문서 그대로 업로드</strong>하면 끝!
          </h4>
          <h4 style="margin-top:10px">
            <strong>사용하던 계약 문서가 없다면?</strong><br/>
            무료 제공되는 <strong>샘플계약서를 이용</strong>하세요.
          </h4>
        </div>
      </div>
      <div>
        <div class="page">
          <img src="@/assets/images/onboard_img03.jpg">
          <div class="usr-box">
            <span class="bk-bl">계약 생성자</span>
          </div>
          <h3>Step2. 간편한 계약 요청</h3>
          <h4>
            서명 참여자가 <strong>직접 입력할 항목 추가</strong>하고,<br/>
            계약서 이외 받아야 할 <strong>추가 자료가 있다면?</strong><br/>
            <strong>첨부파일 요청하여 계약서와 한번에 수신 가능!</strong>
          </h4>
        </div>
      </div>
      <div>
        <div class="page">
          <img src="@/assets/images/onboard_img04.jpg">
          <div class="usr-box">
            <span class="bk-rd">계약 참여자</span>
          </div>
          <h3>Step3. 서명 완료하기</h3>
          <h4>
            서명 요청을 받은 참여자는<br/>
            <strong>필수 입력 항목 입력 후<br/>
              서명</strong>하면 끝!
          </h4>
        </div>
      </div>
      <div>
        <div class="page">
          <img src="@/assets/images/onboard_img05.jpg">
          <div class="usr-box">
            <span class="bk-bl">계약 생성자</span>
            <span class="bk-rd">계약 참여자</span>
          </div>
          <h3>Step4. 계약 체결 완료!</h3>
          <h4>
            모든 참여자의 서명이 완료되면, 계약 완료!<br/>
            <strong>완료 계약서와 감사추적인증서 확인</strong>하세요.<br/>
            <strong>유캔싸인에서 언제든지 확인 가능</strong>합니다.
          </h4>
        </div>
      </div>
      <div>
        <div class="page">
          <img src="@/assets/images/onboard_img06.jpg">
          <h4>
            신규 가입 시
            <strong>무료 계약 10건 제공!</strong><br/>
            지금 바로 유캔싸인 전자계약을<br/>
            경험해 보세요!
          </h4>
          <a-button class="btn btn-blue" @click="closeModal">지금 바로 계약하기</a-button>
        </div>
      </div>
    </a-carousel>
  </a-modal>
  <UcsConfirm :visible="showConfirmModal" cancelText="취소" confirmText="종료" @onClickCancel="showConfirmModal = false" @onClickConfirm="closeModal">
    <template #cusContent>
      서비스 안내창을 종료하고, 지금 바로 시작하시겠습니까?<br/>
      종료 이후에는 안내 내용을 다시 확인 할 수 없습니다.
    </template>
  </UcsConfirm>
</template>

<script>
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons-vue'
import { reactive } from 'vue-demi'
import { toRefs } from '@vueuse/shared'
import UcsConfirm from '@/components/modal/UcsConfirm'
import { useGtag } from 'vue-gtag-next'
import { useStore } from 'vuex'
import { setNotice as submit } from 'api/user'
import { Modal } from 'ant-design-vue'

export default {
  components: {
    LeftCircleOutlined,
    RightCircleOutlined,
    UcsConfirm
  },
  emits: ['confirm'],
  setup (props, { emit }) {
    const { event } = useGtag()
    const store = useStore()
    const state = reactive({
      showConfirmModal: false,
      visible: false,
      currentPage: 0
    })

    const show = () => {
      event('온보딩모달_Open')
      state.visible = true
    }

    const hide = () => {
      state.visible = false
    }

    const onChange = current => {
      state.currentPage = current
    }

    const closeModal = () => {
      submit({ type: 'welcome' })
        .then(() => {
          store.dispatch('userInfo').then(() => {
            state.showConfirmModal = false
            hide()
          })
        })
        .catch((err) => {
          Modal.error({ content: err.msg })
        })
    }

    return {
      ...toRefs(state),
      show,
      hide,
      onChange,
      closeModal
    }
  }

}
</script>

<style lang="less" scoped>
.ant-carousel :deep(.slick-slide h3) {
  color: #000;
}

.ant-carousel :deep(.slick-slide) {
  text-align: center;
  height: 560px;
  line-height: 160px;
  overflow: hidden;
}

.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
  width: 30px;
  height: 30px;
  font-size: 30px;
  color: #2295ff;
  opacity: 0.5;
  z-index: 1;
}

.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}

@media (hover: hover) {
  .ant-carousel :deep(.custom-slick-arrow:hover) {
    opacity: 0.5;
  }
}

.ant-carousel {
  .page {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .btn {
      height: 42px;
      line-height: 42px;
      padding: 0;
      margin: 50px auto;
      width: 200px;
    }

    img {
      object-fit: none;
      margin-bottom: -10px
    }

    h3 {
      text-align: center;
      font-size: 20px;
      margin: 15px 0px 20px;
      line-height: 28px;
      font-weight: 500;

    }

    h4 {
      text-align: center;
      font-size: 16px;
      line-height: 28px;
      font-weight: 400;
      color: #555;

      strong {
        font-weight: 500;
        color: #000
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  .ant-carousel :deep(.slick-slide) {
    text-align: center;
    height: 480px;
    line-height: 160px;
    overflow: hidden;
  }
}

.usr-box {
  display: flex;
  justify-content: center;

  span {
    border-radius: 3px;
    text-align: center;
    min-width: 100px;
    font-size: 14px;
    height: 32px;
    line-height: 32px;
    margin: 3px
  }

  .bk-bl {
    background: #eff7ff;
    color: #2295ff;
  }

  .bk-rd {
    background: #ffebeb;
    color: #e92e2e;
  }
}

.ant-carousel .slick-dots li button {
  background: #2295ff !important;
}

.custom-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
}
</style>
