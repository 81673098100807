import request from 'utils/request'

export const createOrganization = (name) => {
  return request.post('organization', { name: name })
}

export const changeOrganizationName = (name) => {
  return request.put('organization', { name: name })
}

export const checkTeamName = name => {
  return request.post('teams/check/name', { name: name })
}

export const addTeam = name => {
  return request.post('teams', { name: name })
}

export const changeTeamName = (id, name) => {
  return request.put(`teams/${id}`, { name: name })
}

export const deleteTeam = id => {
  return request.delete(`teams/${id}`)
}

export const getTeams = (page, limit) => {
  return request.get(`teams?page=${page}&limit=${limit}`)
}

export const getAllTeamsApi = assigned => {
  // assigned === true , 返回自己有权限的 teams
  return request.get(`teams?assigned=${assigned}`)
}

export const addTeamApi = (parentId, name) => {
  return request.post('teams', {
    parentTeamId: parentId,
    name: name
  })
}

export const changeTeamNameApi = (id, parentId, name) => {
  return request.put(`teams/${id}`, {
    parentTeamId: parentId,
    name: name
  })
}

export const getOrganizationMembers = (page, limit) => {
  return request.get(`members?page=${page}&limit=${limit}`)
}

/**
 * 增加组织成员
 * @param {loginId: String, name: String, email: String, password: String, teamId: Int, roleId: Int, permsScope: Int, permsGroupId: Int}
 */
export const addMembers = param => {
  return request.post('members', param)
}

/**
 * 修改组织成员信息
 * @param memberId
 * @param {teamId: Int, roleId: Int, permsScope: Int, permsGroupId: Int} param
 */
export const modifyMemebers = (memberId, param) => {
  return request.put(`members/${memberId}`, param)
}

export const deleteMember = (memberId) => {
  return request.delete(`members/${memberId}`)
}

export const getRolesAndPermsGroups = () => {
  return request.get('organization/roles_perms_groups')
}

export const getDeleteDocuments = (organizationId, page, limit, filter, keyword) => {
  var url = 'documents?isArchived=true' + `&page=${page}&limit=${limit}`
  if (filter.length > 0 && keyword.length > 0) {
    url += `&${filter}=${keyword}`
  }
  if (organizationId.length > 0) {
    url += `&organizationId=${organizationId}`
  }
  return request.get(url)
}

export const restoreDocument = id => {
  return request.post(`/documents/${id}/restore`)
}
