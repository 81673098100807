<template>
  <a-modal
    v-model:visible="isShow"
    :footer="null"
    class="ucs-modal"
    :bodyStyle="{ padding: 0 }"
    :closable="false"
    :keyboard="false"
    :maskClosable="false"
    :width="width"
    :zIndex="zIndex"
  >
    <div class="modal-header bg-gray">
      <slot name="title"></slot>
      <span v-if="closeBtn" role="button" class="modal-close" @click="onClose">닫기</span>
    </div>
    <div class="modal-content">
      <slot name="content"></slot>
    </div>
  </a-modal>
</template>
<script>
import { reactive, toRefs, watchEffect } from 'vue'

export default {
  props: {
    width: Number,
    visible: Boolean,
    zIndex: { default: 1000 },
    closeBtn: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:visible', 'close'],
  setup (props, { emit }) {
    const state = reactive({
      closeBtn: props.closBtn,
      isShow: props.visible
    })

    const onClose = () => {
      state.isShow = false
      emit('close')
    }

    watchEffect(() => {
      emit('update:visible', state.isShow)
    })

    watchEffect(() => {
      state.isShow = props.visible
    })

    return {
      onClose,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.ucs-modal {
  font-size: 16px;

  .modal-header {
    padding: 2rem;
    font-size: 1.7rem;
    font-weight: 500;
    line-height: 2.2rem;

    .modal-close {
      margin: 24px;
    }
  }

  .modal-content {
    border: none;
  }
}
</style>
