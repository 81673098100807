const settings = {
  state: {
    email: {
      logoId: '',
      logo: '',
      address: '',
      desc: '',
      barColor: '',
      buttonColor: '',
      textColor: ''
    },
    sender: {
      name: ''
    }
  },

  mutations: {
    SET_EMAIL_INFO (state, email) {
      state.email.logoId = email.logoId
      state.email.logo = email.logo
      state.email.address = email.address
      state.email.desc = email.desc
      state.email.barColor = email.barColor
      state.email.buttonColor = email.buttonColor
      state.email.textColor = email.textColor
    },
    SET_SENDER (state, sender) {
      state.sender.name = sender.name
    }
  },

  actions: {
    updateEmailInfo ({ commit }, email) {
      commit('SET_EMAIL_INFO', email)
    },
    updateSender ({ commit }, sender) {
      commit('SET_SENDER', sender)
    }
  }
}

export default settings
