export const RoleType = {
  MainAdmin: '1000',
  CoAdmin: '1001',
  SubAdmin: '1100',
  Normal: '1101'
}

export default {
  // 'view-deleted-files': {
  //   admin: true,
  //   'sub-admin': true,
  //   'role-member-rw': true,
  //   'role-member-r': true
  // },
  // 'recovery-deleted-file': {
  //   params: ['file', 'user'],
  //   admin: true,
  //   'sub-admin': ({ file, user }) => file.teamId === user.organization.teamId,
  //   'role-member-rw': false,
  //   'role-member-r': false
  // },
  // 'complete-delete-file': {
  //   params: ['file', 'user'],
  //   admin: true,
  //   'sub-admin': ({ file, user }) => file.teamId === user.organization.teamId,
  //   'role-member-rw': false,
  //   'role-member-r': false
  // },
  'documents-delete': {
    params: ['documents'],
    'main-admin': ({ documents }) => {
      var hasInvalidItem = false
      for (let index = 0; index < documents.length; index++) {
        const element = documents[index]
        if (element.status !== 'completed' && element.status !== 'request_cancelled') {
          hasInvalidItem = true
          break
        }
      }
      return !hasInvalidItem
    },
    'co-admin': ({ documents }) => {
      var hasInvalidItem = false
      for (let index = 0; index < documents.length; index++) {
        const element = documents[index]
        if (element.status !== 'completed' && element.status !== 'request_cancelled') {
          hasInvalidItem = true
          break
        }
      }
      return !hasInvalidItem
    },
    'sub-admin': ({ documents }) => {
      var hasInvalidItem = false
      for (let index = 0; index < documents.length; index++) {
        const element = documents[index]
        if (element.status !== 'completed' && element.status !== 'request_cancelled') {
          hasInvalidItem = true
          break
        }
      }
      return !hasInvalidItem
    },
    'role-member-rw': false,
    'role-member-r': false,
    normal: ({ documents }) => {
      var hasInvalidItem = false
      for (let index = 0; index < documents.length; index++) {
        const element = documents[index]
        if (element.status !== 'completed' && element.status !== 'request_cancelled') {
          hasInvalidItem = true
          break
        }
      }
      return !hasInvalidItem
    }
  },
  'team-selection': {
    params: ['menu'],
    'main-admin': true,
    'co-admin': true,
    'sub-admin': true,
    'role-member-rw': ({ menu }) => menu ? !menu.isTeamMenu : false,
    'role-member-r': ({ menu }) => menu ? !menu.isTeamMenu : false
  },
  'create-the-template': {
    params: ['currentTeam', 'user'],
    'main-admin': true,
    'co-admin': true,
    'sub-admin': ({
      currentTeam,
      user
    }) => user.organization.permsTeamIds.includes(currentTeam.teamId),
    'role-member-rw': ({
      currentTeam,
      user
    }) => user.organization.teamId === currentTeam.teamId,
    'role-member-r': false
  },
  'team-template:signature-request': {
    params: ['file', 'user'],
    'main-admin': true,
    'co-admin': true,
    'sub-admin': ({
      file,
      user
    }) => user.organization.permsTeamIds.includes(file.teamId),
    'role-member-rw': ({
      file,
      user
    }) => user.organization.teamId === file.teamId,
    'role-member-r': ({
      file,
      user
    }) => user.organization.teamId === file.teamId
  },
  'modify-the-template': {
    params: ['file', 'user'],
    'main-admin': true,
    'co-admin': true,
    'sub-admin': ({
      file,
      user
    }) => user.organization.permsTeamIds.includes(file.teamId),
    'role-member-rw': ({
      file,
      user
    }) => user.organization.teamId === file.teamId,
    'role-member-r': false
  },
  'start-bulk-transmit': {
    params: ['file', 'user'],
    'main-admin': true,
    'co-admin': true,
    'sub-admin': ({
      file,
      user
    }) => user.organization.permsTeamIds.includes(file.teamId),
    'role-member-rw': ({
      file,
      user
    }) => user.organization.teamId === file.teamId,
    'role-member-r': ({
      file,
      user
    }) => user.organization.teamId === file.teamId
  },
  'delete-the-template': {
    params: ['file', 'user'],
    'main-admin': true,
    'co-admin': true,
    'sub-admin': ({
      file,
      user
    }) => user.organization.permsTeamIds.includes(file.teamId),
    'role-member-rw': false,
    'role-member-r': false
  },
  'upload-a-document-to-edit': {
    params: ['currentTeam', 'user'],
    'main-admin': true,
    'co-admin': true,
    'sub-admin': true,
    'role-member-rw': ({
      currentTeam,
      user
    }) => {
      return currentTeam.teamId === user.organization.teamId
    },
    'role-member-r': false
  },
  'document-editing': {
    'main-admin': true,
    'co-admin': true,
    'sub-admin': true,
    'role-member-rw': true,
    'role-member-r': false
  },
  'signature-request': {
    'main-admin': true,
    'co-admin': true,
    'sub-admin': true,
    'role-member-rw': true,
    'role-member-r': true
  },
  'create-a-template': {
    'main-admin': true,
    'co-admin': true,
    'sub-admin': true,
    'role-member-rw': true,
    'role-member-r': false
  },
  download: {
    'main-admin': true,
    'co-admin': true,
    'sub-admin': true,
    'role-member-rw': true,
    'role-member-r': true
  },
  'delete-the-document': {
    'main-admin': true,
    'co-admin': true,
    'sub-admin': true,
    'role-member-rw': true,
    'role-member-r': true
  },
  'point-charge': {
    'main-admin': true,
    'co-admin': false,
    'sub-admin': false,
    'role-member-rw': false,
    'role-member-r': false
  },
  'charging-history': {
    'main-admin': true,
    'co-admin': true,
    'sub-admin': true,
    'role-member-rw': true,
    'role-member-r': true
  },
  'usage-history': {
    'main-admin': true,
    'co-admin': true,
    'sub-admin': true,
    'role-member-rw': true,
    'role-member-r': true
  },
  'add-the-team': {
    'main-admin': true,
    'co-admin': true,
    'sub-admin': false,
    'role-member-rw': false,
    'role-member-r': false
  },
  'modify-the-team': {
    'main-admin': true,
    'co-admin': true,
    'sub-admin': false,
    'role-member-rw': false,
    'role-member-r': false
  },
  'delete-the-team': {
    'main-admin': true,
    'co-admin': true,
    'sub-admin': false,
    'role-member-rw': false,
    'role-member-r': false
  },
  'add-the-sub-account': {
    'main-admin': true,
    'co-admin': true,
    'sub-admin': true,
    'role-member-rw': false,
    'role-member-r': false
  },
  'modify-the-sub-account': {
    params: ['targetUser', 'user'],
    'main-admin': ({
      targetUser,
      user
    }) => targetUser.userId !== user.userId,
    'co-admin': ({
      targetUser,
      user
    }) => {
      return targetUser.userId !== user.userId &&
        targetUser.roleId !== RoleType.MainAdmin &&
        targetUser.roleId !== RoleType.CoAdmin
    },
    'sub-admin': ({
      targetUser,
      user
    }) => {
      if (targetUser.userId === user.userId) {
        return false
      }
      const roleId = targetUser.roleId
      if (roleId === RoleType.MainAdmin || roleId === RoleType.CoAdmin) {
        return false
      }
      if (roleId === RoleType.SubAdmin) {
        return targetUser.teamId !== user.organization.teamId && user.organization.permsTeamIds.includes(targetUser.teamId)
      } else {
        return user.organization.permsTeamIds.includes(targetUser.teamId)
      }
    },
    'role-member-rw': false,
    'role-member-r': false
  },
  'modify-the-sub-account:belonged-team-setting': {
    'main-admin': true, // get all team
    'co-admin': true,
    'sub-admin': false, // only get belonged team
    'role-member-rw': false,
    'role-member-r': false
  },
  'modify-the-sub-account:role-setting': {
    params: ['roleId', 'user'],
    'main-admin': ({ roleId }) => roleId === RoleType.CoAdmin || roleId === RoleType.SubAdmin || roleId === RoleType.Normal,
    'co-admin': ({ roleId }) => roleId === RoleType.CoAdmin || roleId === RoleType.SubAdmin || roleId === RoleType.Normal,
    'sub-admin': ({ roleId }) => roleId === RoleType.SubAdmin || roleId === RoleType.Normal,
    'role-member-rw': false,
    'role-member-r': false
  },
  'delete-the-sub-account': {
    params: ['targetUser', 'user'],
    'main-admin': ({
      targetUser,
      user
    }) => {
      return targetUser.userId !== user.userId
    },
    'co-admin': ({
      targetUser,
      user
    }) => {
      return targetUser.userId !== user.userId &&
        targetUser.roleId !== RoleType.MainAdmin &&
        targetUser.roleId !== RoleType.CoAdmin
    },
    'sub-admin': ({
      targetUser,
      user
    }) => {
      if (targetUser.userId === user.userId) {
        return false
      }
      const roleId = targetUser.roleId
      if (roleId === RoleType.MainAdmin || roleId === RoleType.CoAdmin) {
        return false
      }
      if (roleId === RoleType.SubAdmin) {
        return targetUser.teamId !== user.organization.teamId && user.organization.permsTeamIds.includes(targetUser.teamId)
      } else {
        return user.organization.permsTeamIds.includes(targetUser.teamId)
      }
    },
    'role-member-rw': false,
    'role-member-r': false
  },
  'change-the-organization-name': {
    'main-admin': true,
    'co-admin': false,
    'sub-admin': false,
    'role-member-rw': false,
    'role-member-r': false
  },
  'brand-customizing': {
    'main-admin': true,
    'co-admin': false,
    'sub-admin': false,
    'role-member-rw': false,
    'role-member-r': false
  },
  'create-the-folder': {
    params: ['currentTeam', 'user'],
    'main-admin': true,
    'co-admin': true,
    'sub-admin': ({
      currentTeam,
      user
    }) => user.organization.permsTeamIds.includes(currentTeam.teamId),
    'role-member-rw': ({
      currentTeam,
      user
    }) => user.organization.teamId === currentTeam.teamId,
    'role-member-r': ({
      currentTeam,
      user
    }) => user.organization.teamId === currentTeam.teamId
  },
  'contract-deletion': {
    'main-admin': true,
    'co-admin': true,
    'sub-admin': true,
    'role-member-rw': false,
    'role-member-r': false
  },
  'recovery-deleted-contract': {
    'main-admin': true,
    'co-admin': true,
    'sub-admin': true,
    'role-member-rw': false,
    'role-member-r': false
  },
  'complete-deleted-contract': {
    'main-admin': true,
    'co-admin': true,
    'sub-admin': true,
    'role-member-rw': false,
    'role-member-r': false
  }
}
