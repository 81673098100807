<template>
  <a-modal
    v-model:visible="isShow"
    :bodyStyle="{ padding: 0 }"
    :centered="centered"
    :closable="false"
    :footer="null"
    :keyboard="false"
    :maskClosable="false"
    :width="width"
    class="ucs-simple-modal"
  >
    <div class="ucs-modal modal-show">
      <div class="ucs-modal-header">
        <span class="modal-close" role="button" @click="isShow = false">닫기</span>
      </div>
      <div class="text-center ucs-modal-content">
        <div class="cont-header">
          <slot name="title"></slot>
        </div>
        <div class="cont-body">
          <b class="text-gray">
            <slot name="textContent"></slot>
          </b>
          <slot name="content"></slot>
        </div>
        <div class="btn-horizon btns">
          <slot name="footer">
            <div v-if="footerBtnYn">
              <a-button class="btn btn-gray-line" @click="onCancel">{{ cancelText }}</a-button>
              <a-button class="btn btn-blue" @click="onConfirm">{{ okText }}</a-button>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { reactive, toRefs } from 'vue'

export default {
  props: {
    width: {
      type: Number,
      default: 380
    },
    okText: {
      type: String,
      default: '확인'
    },
    cancelText: {
      type: String,
      default: '취소'
    },
    centered: {
      type: Boolean,
      default: false
    },
    footerBtnYn: {
      type: Boolean,
      default: true
    }
  },
  setup (props, { emit }) {
    const state = reactive({
      isShow: false
    })
    const show = () => {
      state.isShow = true
    }
    const hide = () => {
      state.isShow = false
    }
    const onCancel = () => {
      state.isShow = false
      emit('cancel')
    }
    const onConfirm = () => {
      emit('confirm')
    }
    return {
      show,
      onCancel,
      onConfirm,
      hide,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.ucs-simple-modal {
  .ucs-modal-header {
    padding: 10px;
  }

  .cont-body {
    padding: 10px 39px 20px;
  }

  .btns {
    margin-top: 10px;

    .btn {
      height: 40px;

      &:first-of-type {
        margin-right: 10px;
      }
    }
  }
}
</style>
