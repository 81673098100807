import request from 'utils/request'

export const createEditingDocument = (docData) => {
  return request.post('/editingDocuments', docData)
}

export const updateDocument = (editingDocumentId, postData) => {
  return request.put(`/editingDocuments/${editingDocumentId}/file`, postData)
}

export const getEditDocFile = editingDocumentId => {
  return request.get(`/editingDocuments/${editingDocumentId}/file`)
}
