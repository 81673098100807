<template>
  <a-modal v-bind="$attrs" :closable="false" :footer="null" class="customer-modal">

    <div class="cus-icon">
      <slot name="cusIcon">
        <ExclamationCircleFilled class="icon"/>
      </slot>
    </div>

    <div class="cus-title" style="">
      <slot name="cusTitle">
      </slot>
    </div>

    <div class="cus-content" style="min-height: 50px;">
      <slot name="cusContent">
      </slot>
    </div>

    <div class="cus-footer">
      <slot name="cusFooter">
        <a-button key="back" class="cus-btn btn" @click="onClickCancel">{{ props.cancelText }}</a-button>
        <a-button key="submit" class="cus-btn btn" type="primary" @click="onClickConfirm">{{ props.confirmText }}</a-button>
      </slot>
    </div>

  </a-modal>
</template>
<script setup>
import { ExclamationCircleFilled } from '@ant-design/icons-vue'
import { defineEmits, defineProps } from 'vue'

const emit = defineEmits(['onClickCancel', 'onClickConfirm'])
const props = defineProps(['confirmText', 'cancelText'])

const onClickCancel = () => {
  emit('onClickCancel')
}
const onClickConfirm = () => {
  emit('onClickConfirm')
}

</script>
<style lang="less">
.customer-modal {
  .cus-icon {
    margin-top: 16px;
    text-align: center;

    .icon {
      font-size: 60px;
      color: #1890ff;
    }
  }

  .cus-title {
    margin-top: 16px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }

  .cus-content {
    margin-top: 8px;
    min-height: 50px;
    text-align: center;
  }

  .cus-footer {
    margin-top: 16px;
    display: flex;
    justify-content: center;

    .cus-btn {
      margin: 0 0.5rem;
      min-width: 25%;
    }
  }
}
</style>
