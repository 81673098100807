<template>
  <div id="user-wrapper">
    <router-view></router-view>
  </div>
</template>

<script>
import 'assets/styles/user.less'

export default {
  setup () {
    // eslint-disable-next-line no-undef
    ChannelIO('showChannelButton')
  }
}
</script>
