<template>
  <a-modal
    :closable="false"
    :footer="false"
    :keyboard="false"
    :maskClosable="false"
    :visible="visible"
    :zIndex="100"
    centered
    @cancel="visible = false"
  >
    <div class="page">
      <!--      <img src="@/assets/images/request_pay.png">-->
      <div class="video-box">
        <video autoplay loop="" muted="" playsinline="">
          <source src="@/assets/images/video/request.mp4" type="video/mp4">
        </video>
      </div>
      <h4>
        최초 1회, 페이앱 계약서 작성하고,<br/>
        결제금액을 자동 정산 받으세요!
      </h4>
      <ul>
        <li>
          <CheckSquareOutlined/>
          이미 계약서를 작성했다면, 추가 작성은 NO!
        </li>
        <li>
          <CheckSquareOutlined/>
          회원가입 시 입력한 메일에서 게약서 확인하세요.
        </li>
        <li>
          <CheckSquareOutlined/>
          메일함에 계약서가 없다면? 고객센터로 문의주세요.
        </li>
      </ul>
      <p>
        자세한 정산안내가 궁금하다면?
        <a href="https://payapp.kr/homepage/guide/guide5.html" target="_blank">여기를 클릭하세요</a>

      </p>
    </div>
    <div class="close-contents">
      <a-checkbox v-model:checked="dontShowAgain">다시 보지않기</a-checkbox>
      <a-button class="close-button" style="background: #aaa; border-color:#aaa" type="primary" @click="hide">닫기</a-button>
    </div>
  </a-modal>
</template>

<script>
import { CheckSquareOutlined } from '@ant-design/icons-vue'
import { reactive, toRefs } from 'vue'
import { setNotice as submit } from 'api/user'
import { Modal } from 'ant-design-vue'
import { useStore } from 'vuex'

export default {
  components: {
    CheckSquareOutlined
  },
  setup () {
    const store = useStore()
    const state = reactive({
      visible: false,
      dontShowAgain: false
    })

    const show = () => {
      state.visible = true
    }

    const hide = () => {
      if (state.dontShowAgain) {
        submit({ type: 'request_kakao_payment' })
          .then(() => {
            store.dispatch('userInfo')
            state.visible = false
          })
          .catch((err) => {
            Modal.error({ content: err.msg })
          })
      } else {
        state.visible = false
      }
    }
    return {
      ...toRefs(state),
      show,
      hide
    }
  }

}
</script>

<style lang="less" scoped>
.page {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 1rem;

  .video-box {
    margin: 2rem auto;
    width: 300px;
    height: 92px;
  }

  .btn {
    height: 42px;
    line-height: 42px;
    padding: 0;
    margin: 50px auto;
    width: 200px;
  }

  img {
    object-fit: cover;
    padding-bottom: 3rem;
    width: 50%;
    max-width: 150px;
    margin: 0 auto;
  }

  h3 {
    text-align: center;
    font-size: 20px;
    margin: 15px 0px 20px;
    line-height: 28px;
    font-weight: 500;

  }

  h4 {
    text-align: center;
    line-height: 28px;
    font-weight: 500;
    font-size: 1.9rem;
    color: #000;
    margin-top: 1rem;
  }

  ul {
    margin: 3rem 0 1rem 0;

    li {
      width: 80%;
      font-size: 1.5rem;
      padding: 1rem;
      background: #f7f7f7;
      margin: 0 auto 1rem;

      .anticon {
        margin-right: 1rem;
        color: #1890ff;
        font-weight: 700;
        border-radius: 5px;
      }
    }

    @media only screen and (max-width: 768px) {
      li {
        width: 100%
      }
    }
  }

  p {
    text-align: center;
    color: #999;
    margin-bottom: 2rem;

    a {
      color: #1890ff;
      text-decoration: underline;
      margin-left: 0.5rem
    }
  }
}

.close-contents {
  text-align: right;
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;

  .close-button {
    width: 60px;
    height: 32px;
  }
}
</style>
