<template>
  <svg id="Layer_2" data-name="Layer 2" viewBox="0 0 214.57 55.21" xmlns="http://www.w3.org/2000/svg">
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <path class="cls-1"
              d="M35.2,10.33c-1.4,0-2.61-.51-3.63-1.53-1.02-1.02-1.53-2.23-1.53-3.63s.51-2.61,1.53-3.63c1.02-1.02,2.23-1.53,3.63-1.53s2.61.51,3.63,1.53c1.02,1.02,1.53,2.23,1.53,3.63s-.51,2.61-1.53,3.63c-1.02,1.02-2.23,1.53-3.63,1.53Z"/>
        <path :class="fill === 'white' ? 'cls-2-white' : 'cls-2'"
              d="M1.32,44.63v-7.03h14.96c.48,0,.91-.1,1.29-.3.38-.2.68-.48.9-.84.22-.36.33-.76.33-1.2,0-.48-.11-.9-.33-1.26-.22-.36-.52-.65-.9-.87-.38-.22-.81-.33-1.29-.33h-5.77c-1.96,0-3.73-.31-5.32-.93-1.58-.62-2.84-1.62-3.78-3-.94-1.38-1.41-3.19-1.41-5.44,0-1.72.43-3.29,1.29-4.72.86-1.42,2.02-2.56,3.48-3.42,1.46-.86,3.07-1.29,4.84-1.29h14.35v7.03h-13.57c-.6,0-1.13.2-1.59.6-.46.4-.69.92-.69,1.56s.22,1.17.66,1.59c.44.42.98.63,1.62.63h5.77c2.2,0,4.1.33,5.71.99,1.6.66,2.84,1.69,3.72,3.09.88,1.4,1.32,3.24,1.32,5.53,0,1.76-.44,3.37-1.32,4.84s-2.06,2.62-3.54,3.48c-1.48.86-3.14,1.29-4.98,1.29H1.32Z"/>
        <path :class="fill === 'white' ? 'cls-2-white' : 'cls-2'" d="M31.06,44.63V13.99h8.29v30.63h-8.29Z"/>
        <path :class="fill === 'white' ? 'cls-2-white' : 'cls-2'"
              d="M77.81,44.63v-17.6c0-2.52.59-4.82,1.77-6.91,1.18-2.08,2.89-3.74,5.14-4.99,2.24-1.24,4.92-1.86,8.05-1.86s5.86.62,8.08,1.86c2.22,1.24,3.92,2.9,5.1,4.99,1.18,2.08,1.77,4.38,1.77,6.91v17.6h-8.23v-17.6c0-1.24-.3-2.37-.9-3.39-.6-1.02-1.41-1.83-2.43-2.43-1.02-.6-2.15-.9-3.39-.9s-2.37.3-3.39.9c-1.02.6-1.83,1.41-2.43,2.43-.6,1.02-.9,2.15-.9,3.39v17.6h-8.23Z"/>
        <path :class="fill === 'white' ? 'cls-2-white' : 'cls-2'"
              d="M58.57,13.66c-8.13,0-14.68,6.78-14.3,14.99.34,7.29,6.33,13.29,13.62,13.62,8.21.38,14.99-6.17,14.99-14.3v-14.31h-14.31ZM65.23,27.97c0,3.78-3.15,6.83-6.97,6.65-3.39-.16-6.18-2.95-6.34-6.34-.18-3.82,2.87-6.97,6.65-6.97h6.66v6.66Z"/>
        <path :class="fill === 'white' ? 'cls-2-white' : 'cls-2'"
              d="M59.44,55.21c9.42,0,12.73-4.08,13.3-4.9l-5.71-4.48s-.09.1,0,0c-.02.02-1.48,2.07-7.59,2.07-6.1,0-7.57-2.05-7.59-2.07.09.1,0,0,0,0l-5.71,4.48c.57.82,3.88,4.9,13.3,4.9Z"/>
        <path class="cls-1"
              d="M127.21,44.27c-1.27-.52-2.36-1.21-3.25-2.09-.9-.87-1.64-1.85-2.24-2.92h-.12s0,15.95,0,15.95h-8.18v-26.04c0-2.94.62-5.63,1.85-8.06,1.23-2.43,3.02-4.36,5.37-5.79,2.35-1.43,5.23-2.15,8.66-2.15s6.29.71,8.71,2.12c2.43,1.41,4.29,3.33,5.58,5.76,1.29,2.43,1.94,5.13,1.94,8.12s-.57,5.56-1.7,7.97c-1.13,2.41-2.74,4.33-4.81,5.76-2.07,1.43-4.5,2.15-7.28,2.15-1.75,0-3.26-.26-4.54-.78ZM133.63,36.87c1.17-.8,2.07-1.88,2.69-3.25.62-1.37.93-2.9.93-4.57s-.31-3.17-.93-4.51c-.62-1.33-1.51-2.4-2.69-3.19-1.17-.8-2.58-1.19-4.21-1.19s-2.96.4-4.12,1.19c-1.15.8-2.04,1.86-2.66,3.19-.62,1.33-.93,2.84-.93,4.51s.31,3.19.93,4.57c.62,1.37,1.5,2.46,2.66,3.25,1.15.8,2.53,1.19,4.12,1.19s3.03-.4,4.21-1.19Z"/>
        <path class="cls-1"
              d="M163.25,45.05c-2.79,0-5.21-.72-7.28-2.15-2.07-1.43-3.67-3.35-4.81-5.76-1.13-2.41-1.7-5.06-1.7-7.97s.65-5.69,1.94-8.12c1.29-2.43,3.15-4.35,5.58-5.76,2.43-1.41,5.33-2.12,8.71-2.12s6.31.72,8.66,2.15c2.35,1.43,4.14,3.36,5.37,5.79,1.23,2.43,1.85,5.11,1.85,8.06v15.16h-8.18v-5.07h-.12c-.6,1.07-1.34,2.05-2.24,2.92-.9.88-1.98,1.57-3.25,2.09-1.27.52-2.79.78-4.54.78ZM165.58,38.07c1.59,0,2.96-.4,4.12-1.19,1.15-.8,2.04-1.88,2.66-3.25.62-1.37.93-2.9.93-4.57s-.31-3.17-.93-4.51c-.62-1.33-1.5-2.4-2.66-3.19-1.15-.8-2.53-1.19-4.12-1.19s-3.03.4-4.21,1.19c-1.17.8-2.07,1.86-2.69,3.19-.62,1.33-.93,2.84-.93,4.51s.31,3.19.93,4.57c.62,1.37,1.51,2.46,2.69,3.25,1.17.8,2.58,1.19,4.21,1.19Z"/>
        <g>
          <path class="cls-1"
                d="M201.27,55.21c9.42,0,12.73-4.08,13.3-4.9l-5.71-4.48s-.09.1,0,0c-.02.02-1.48,2.07-7.59,2.07-6.1,0-7.57-2.05-7.59-2.07.09.1,0,0,0,0l-5.71,4.48c.57.82,3.88,4.9,13.3,4.9Z"/>
          <path class="cls-1"
                d="M214.57,13.38h-7.65v14.44c0,.66-.11,1.24-.29,1.76-.62,2.04-2.19,3.7-4.19,4.4-.03.01-.07.02-.1.03-.15.05-.31.1-.47.14-.08.02-.17.04-.25.05-.12.02-.23.05-.35.07-.13.02-.27.03-.4.04-.08,0-.17.02-.25.02-.12,0-.24,0-.36,0s-.24,0-.36,0c-.08,0-.17-.02-.25-.02-.13-.01-.27-.02-.4-.04-.12-.02-.24-.04-.35-.07-.08-.02-.17-.03-.25-.05-.16-.04-.31-.09-.47-.14-.03-.01-.07-.02-.1-.03-2.07-.73-3.69-2.46-4.26-4.6-.02-.06-.04-.13-.05-.19-.03-.11-.05-.23-.07-.35,0-.02,0-.04,0-.05-.09-.42-.14-.87-.12-1.36,0-.2.02-.4.03-.59-.09-4.49-.23-8.97-.29-13.46h-7.38v14.31c0,.11.01.22.02.34,0,.11,0,.22,0,.34,0,.15.03.3.04.45.02.21.03.42.06.63.03.24.07.47.11.71.04.25.09.5.14.75.03.14.07.27.11.41,1.21,4.63,4.67,8.34,9.16,9.89.07.02.14.04.21.07.33.11.66.21,1,.3.18.05.36.08.54.12.25.05.5.11.76.15.28.04.57.07.86.09.18.02.36.04.54.05.03,0,.06,0,.09,0,.23.01.45,0,.68,0,.23,0,.45.01.68,0,.03,0,.06,0,.09,0,.18,0,.36-.03.54-.05.29-.03.57-.05.86-.09.26-.04.51-.09.76-.15.18-.04.36-.07.54-.12.34-.08.67-.19,1-.3.07-.02.14-.04.21-.07,4.48-1.55,7.94-5.26,9.16-9.89.04-.14.08-.27.11-.41.06-.25.1-.5.14-.75.04-.24.08-.47.11-.71.03-.21.04-.42.06-.63.01-.15.04-.3.04-.45,0-.11,0-.22,0-.34,0-.11.02-.22.02-.34v-14.31Z"/>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'SignPayIcon',
  props: {
    fill: {
      type: String,
      defaultValue: 'none'
    }
  }
}
</script>

<style scoped>

.cls-1 {
  fill: #1890ff;
}

.cls-1, .cls-2, .cls-2-white {
  stroke-width: 0px;
}

.cls-2 {
  fill: #3e4252;
}

.cls-2-white {
  fill: #ffffff;
}

</style>
